@use "globals" as *;

.slider-input {
  --slider-primary-color: var(--primary-color);
  --slider-primary-color-rgb: var(--primary-color-rgb);
  --slider-track-color: var(--input-color);

  &-track {
    background-color: var(--slider-track-color);
  }

  &-thumb {
    background-color: var(--slider-primary-color);
  }

  &-indicator {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    background-color: rgba(var(--slider-primary-color-rgb), 0.1);
    border: 1px solid rgba(var(--slider-primary-color-rgb), 0.3);
    color: var(--slider-primary-color);
    padding: var(--gutter-xs) var(--gutter-sm);
    border-radius: var(--radius-sm);
    z-index: 10;
  }

  &-value {
    background-color: var(--slider-primary-color);
  }

  &-blue {
    --slider-primary-color: var(--blue-color);
    --slider-primary-color-rgb: var(--blue-color-rgb);
  }
}
