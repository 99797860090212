@use "globals" as *;

.terms {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5rem 0;
  min-height: 70vh;

  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.85rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 2rem;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 16px;
    margin: 0 0 10px;
    line-height: 1.5;
    text-align: justify;
  }

  b {
    font-weight: 700;
  }

  ul {
    padding-left: 1.5rem !important;
    text-indent: -1.7rem;

    li {
      line-height: 1.5;
      margin-bottom: 0.75rem;

      &:before {
        content: "\2014";
        color: var(--primary-color);
        margin-right: 0.75rem;
      }
    }
  }
}
