@use "globals" as *;

.chart {
  &-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    border-radius: var(--radius-lg);
    border: 1px solid var(--border-color);
    gap: var(--gutter-md);
    padding: var(--gutter);
    transition: var(--time) var(--curve-fast);
    --cover-size: 150px;

    &:hover {
      background-color: var(--secondary-bg-color);
      transition: var(--time) var(--curve-fast);
    }

    @include breakpoint(sm) {
      gap: var(--gutter);
      padding: 0.75rem;
      --cover-size: 90px;
    }

    &.position-1 {
      background-color: rgba(var(--gold-color-rgb), 0.1);
      border-color: rgba(var(--gold-color-rgb), 0.3);

      &:hover {
        background-color: rgba(var(--gold-color-rgb), 0.3);
        border-color: rgba(var(--gold-color-rgb), 0.7);
      }

      .chart-item-cover {
        background: linear-gradient(to top, rgba(var(--gold-color-rgb), 0.2), rgba(var(--gold-color-rgb), 0.5));
        border-color: var(--gold-color);
      }

      .chart-item-position {
        color: var(--gold-color);
      }

      .track-player .overlay {
        background-color: rgba(var(--gold-color-rgb), 0.75);
      }
    }

    &.position-2 {
      background-color: rgba(var(--silver-color-rgb), 0.1);
      border-color: rgba(var(--silver-color-rgb), 0.3);

      &:hover {
        background-color: rgba(var(--silver-color-rgb), 0.2);
        border-color: rgba(var(--silver-color-rgb), 0.7);
      }

      .chart-item-cover {
        background: linear-gradient(to top, rgba(var(--silver-color-rgb), 0.2), rgba(var(--silver-color-rgb), 0.5));
        border-color: var(--silver-color);
      }

      .chart-item-position {
        color: var(--silver-color);
      }

      .track-player .overlay {
        background-color: rgba(var(--silver-color-rgb), 0.75);
      }
    }

    &.position-3 {
      background-color: rgba(var(--bronze-color-rgb), 0.1);
      border-color: rgba(var(--bronze-color-rgb), 0.3);

      &:hover {
        background-color: rgba(var(--bronze-color-rgb), 0.2);
        border-color: rgba(var(--bronze-color-rgb), 0.7);
      }

      .chart-item-cover {
        background: linear-gradient(to top, rgba(var(--bronze-color-rgb), 0.2), rgba(var(--bronze-color-rgb), 0.5));
        border-color: var(--bronze-color);
      }

      .chart-item-position {
        color: var(--bronze-color);
      }

      .track-player .overlay {
        background-color: rgba(var(--bronze-color-rgb), 0.75);
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: var(--cover-size);
      padding: var(--gutter-xs) 0;

      h3 {
        font-size: 1.6rem;
        line-height: 1.25;
        font-weight: 700;
        text-wrap: balance;

        @include breakpoint(sm) {
          font-size: 1.2rem;
        }
      }
    }

    &-cover {
      position: relative;
      aspect-ratio: 1/1;
      width: var(--cover-size);
      min-width: var(--cover-size);
      height: var(--cover-size);
      min-height: var(--cover-size);
      border: 1px solid transparent;
      border-radius: var(--radius-sm);
      padding: var(--gutter-sm);
      background-color: rgba(var(--primary-text-color-rgb), 0.1);

      @include breakpoint(sm) {
        padding: var(--gutter-xs);
      }

      img {
        border-radius: 0.25rem;
      }

      .track-player {
        --track-cover-size: 100%;
      }
    }

    &-label {
      @include breakpoint(sm) {
        font-size: 0.8rem;
      }
    }

    &-meta {
      display: flex;
      flex-wrap: wrap;
      gap: var(--gutter-sm);
      margin-top: var(--gutter-sm);
    }

    &-position {
      position: absolute;
      left: -0.4rem;
      bottom: -0.4rem;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: var(--white-color);
      border-radius: var(--radius-sm);
      z-index: 10;

      @include breakpoint(sm) {
        font-size: 2rem;
        left: var(--gutter);
        bottom: var(--gutter);
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: var(--gutter);
  }

  &-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
