@use "globals" as *;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-lg);
  background-color: var(--secondary-bg-color);
  overflow: hidden;

  &.hoverable {
    cursor: pointer;
    transition: var(--time) all var(--curve-fast);

    &:hover {
      background: rgba(var(--primary-text-color-rgb), 0.1) !important;
      transition: var(--time) all var(--curve-fast);
    }
  }

  &-image {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .overlay {
    opacity: 0.5;
  }

  .overlay-title {
    position: absolute;
    left: var(--gutter);
    bottom: var(--gutter);
    width: calc(100% - var(--gutter) * 2);
    color: var(--white-color);
    text-align: left;
    margin: 0;
    z-index: 10;
    transition: opacity 0.3s ease;
  }
}

.card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--gutter);
  @include shadow-border;

  .card-content {
    .card-icon {
      font-size: 2rem;
      color: var(--primary-color);
      margin-bottom: var(--gutter-sm);
    }

    h3 {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.025rem;
      margin-bottom: var(--gutter-xs);
      opacity: 0.9;
    }

    p {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .card-footer {
    width: 100%;
    margin-top: auto;

    .button {
      width: 100%;
    }
  }
}

.card-preview {
  pointer-events: none;
  overflow: hidden;
  filter: blur(8px);
}
