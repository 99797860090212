@use "globals" as *;

.partners {
  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(8%, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: var(--gutter-lg);

    @include breakpoint(md) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: var(--gutter);
      overflow: hidden;
    }

    &-item {
      position: relative;
      text-align: center;
      aspect-ratio: 1 / 1;
      border-radius: var(--radius);
      transition: var(--time) all ease-in-out;

      > *,
      span {
        display: flex;
        width: 100%;
        height: 100%;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: var(--radius);
        @include shadow-border;
        transition: var(--time) all ease-in-out;

        &:hover {
          transition: var(--time) all ease-in-out;
        }
      }
    }
  }
}
