@use "sass:math";
@use "sass:map";

$type-ratios: (
  "minorSecond": 1.067,
  "majorSecond": 1.125,
  "minorThird": 1.2,
  "majorThird": 1.25,
  "perfectFourth": 1.333,
  "augmentedFourth": 1.414,
  "perfectFifth": 1.5,
  "goldenRatio": 1.618
);

@function type-ratio($key) {
  @return map.get($type-ratios, $key);
}

$type-base-size: 1rem;
$type-size-ratio: type-ratio("minorThird");

$type-levels: 6, 5, 4, 3, 2, 1;

$type-styles: ();
$level-size: $type-base-size;
@each $level in $type-levels {
  $level-size: $level-size * $type-size-ratio;
  $type-styles: map.merge($type-styles, (#{"h"}$level: $level-size));

  h#{$level},
  .h#{$level} {
    font-size: $level-size;
    line-height: calc(2px + 2ex + 2px);
    margin-bottom: 0.6em;

    @if ($type-size-ratio > 1.2) {
      $level-unitless: math.div($level-size, ($level-size * 0 + 1));

      $fluid-reduction: if($level-size > 4, 0.5, 0.33);
      $fluid-min: $level-unitless - ($fluid-reduction * $level-unitless);

      $fluid-min: if($fluid-min > $type-base-size, $fluid-min, 1);
      $fluid-scaler: ($level-unitless - $fluid-min) + 4vw;

      font-size: clamp(#{$fluid-min}rem, #{$fluid-scaler} + 1rem, #{$level-size});
    }
  }
}

@function type-style($key) {
  @return map.get($type-styles, $key);
}
