@use "globals" as *;

.solution {
  &-cover-grid-wrapper {
    position: relative;
    height: 180px;
    overflow: hidden;
    mask-image: radial-gradient(var(--primary-bg-color), transparent 90%);
  }

  &-cover-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 0.25rem;
    margin-top: var(--gutter);

    @include breakpoint(sm) {
      grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    }

    .cell {
      position: relative;
      border-radius: var(--radius-sm);
      overflow: hidden;
      filter: grayscale(100%);
      opacity: 0.2;
      transition: var(--time) var(--curve-fast);

      @include breakpoint(sm) {
        border-radius: var(--radius-xs);
      }

      &.active {
        opacity: 1;
        filter: grayscale(0%);
      }

      &:hover {
        opacity: 1;
        filter: grayscale(0%);
        transition: var(--time) var(--curve-fast);
      }
    }
  }
}

.solution-card {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: var(--gutter-md);
  border: 1px solid var(--border-color);
  gap: var(--gutter);
  --icon-size: 54px;

  @include breakpoint(sm) {
    padding: var(--gutter);
  }

  &:hover {
    .card-content h3 {
      transform: translateY(0);
      transition: var(--time) all ease-in-out;
    }

    .card-content p {
      opacity: 1;
      transform: translateY(0);
      transition: var(--time) all ease-in-out;
    }
  }

  .card-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gutter-md);
    margin-top: auto;

    h3 {
      font-size: 1.5rem;
      line-height: 1.4;
      text-wrap: balance;
      margin: 0 0 var(--gutter-xs) 0;
      transition: var(--time) all ease-in-out;
    }

    p {
      font-size: 1rem;
      opacity: 0.8;
      letter-spacing: 0.002rem;
      margin: 0;
      text-wrap: balance;
    }
  }

  .card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    width: var(--icon-size);
    height: var(--icon-size);
    flex-shrink: 0;
    border-radius: var(--radius-sm);
    margin-bottom: var(--gutter-md);
  }

  .card-footer {
    margin-top: auto;
  }
}

.solution-video {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoint(md) {
    grid-template-columns: 1fr;
    gap: var(--gutter-lg);
  }

  .video {
    box-shadow: none;

    video {
      object-fit: cover;
    }
  }
}
