@use "globals" as *;

.command-item {
  transition: var(--faster) all var(--curve-fast);

  &[data-selected="true"],
  &:hover {
    background: rgba(var(--primary-text-color-rgb), 0.1);
    transition: var(--faster) all var(--curve-fast);
  }
}
