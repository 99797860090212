@use "globals" as *;

.review-marquee {
  .review-card {
    width: 400px;
    margin: 0 var(--gutter);

    @include breakpoint(sm) {
      width: 80vw;
    }

    &:hover {
      transition: var(--time) ease-in-out;
    }
  }

  .overlay {
    background-color: var(--primary-bg-color);
    --gradient-color: rgba(var(--primary-bg-color-rgb), 1), rgba(var(--primary-bg-color-rgb), 0) !important;
  }
}

.review-card {
  display: flex;
  flex-direction: column;
  background-color: rgba(var(--secondary-bg-color-rgb), 0.5);
  border-radius: var(--radius);
  color: var(--primary-text-color);
  padding: var(--gutter-md);
  @include shadow-border;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gutter);
    margin-bottom: var(--gutter);

    time {
      opacity: 0.75;
    }

    img {
      max-width: 50%;
    }
  }

  &-body {
    text-align: left;

    h5 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0 0 var(--gutter-sm);
    }

    span {
      font-size: 1rem;
      line-height: 1;
      font-weight: 300;
      opacity: 0.7;
      margin: 0 0 var(--gutter-sm);
    }
  }
}
