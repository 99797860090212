@use "globals" as *;

.rating {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  --star-size: 1.5rem;
}

.star,
.star-filled {
  svg {
    width: var(--star-size);
    height: var(--star-size);
  }

  .star {
    color: var(--primary-text-color);
    opacity: 0.15;
  }
}

.star-filled {
  color: var(--yellow-color);
}
