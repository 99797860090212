@use "globals" as *;

.slider {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  list-style: none;
  padding: 0 0 3rem 0;
  z-index: 1;
}

.swiper-pagination {
  width: 100%;
  bottom: var(--gutter);

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 4px;
    background-color: rgba(var(--primary-text-color-rgb), 0.5);
  }

  .swiper-pagination-bullet-active {
    background-color: var(--primary-text-color);
  }
}
