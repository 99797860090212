@use "globals" as *;

.tutorials-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--gutter);

  @include breakpoint(sm) {
    grid-template-columns: 1fr;
  }
}

.tutorial-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-lg);
  background-color: var(--secondary-bg-color);
  overflow: hidden;
  padding: var(--gutter-md);

  h4 {
    font-size: 1.25rem;
    text-align: left;
    margin-bottom: var(--gutter);
  }

  iframe {
    border-radius: var(--radius-lg);
    overflow: hidden;
  }
}
