@use "globals" as *;

.charts {
  padding: 3rem 0;

  .chart {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    h5 {
      font-size: 1.25rem;
      text-transform: uppercase;
      opacity: 0.9;
      letter-spacing: 2px;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}

.chart-analytics {
  .chart-lines {
    height: 400px;
    position: relative;
    width: 100%;
  }
}

.chart-lines {
  width: 100%;
  height: 100%;
  text {
    fill: var(--primary-text-color) !important;
    opacity: 0.7 !important;
  }

  line {
    stroke: var(--primary-text-color) !important;
    opacity: 0.7 !important;
  }
}

.chart-pie {
  width: 100%;
  height: 100%;

  text {
    font-weight: 500 !important;
    fill: var(--primary-text-color) !important;
  }
}
