@use "sass:map";

:root {
  --scrollbar-width: 10px;
  --scrollbar-thumb-color: transparent;
  --scrollbar-thumb-hover: rgba(var(--primary-text-color-rgb), 0.1);
  --scrollbar-track-color: transparent;
  --scrollbar-transition: background-color 0.3s ease;
}

.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
  transition: var(--time) all var(--curve-out);

  &:hover {
    scrollbar-color: var(--scrollbar-thumb-hover) var(--scrollbar-track-color);
    transition: var(--time) all var(--curve-out);
  }

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: calc(var(--scrollbar-width) / 2);
    transition: var(--scrollbar-transition);
  }

  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-hover);
  }
}

.scroll-thin {
  scrollbar-width: thin;
}
