@use "globals" as *;

.playlist-overview {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: var(--gutter-lg);
  width: 100%;

  @include breakpoint(md) {
    grid-template-columns: 1fr;
  }

  .playlist-cover {
    width: var(--playlist-cover-size);
    height: var(--playlist-cover-size);
    flex-shrink: 0;
    --playlist-cover-size: 250px;

    @include breakpoint(md) {
      --playlist-cover-size: 100px;
      display: none;
    }
  }

  .playlist-meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--gutter-sm);
    margin-bottom: var(--gutter-sm);
  }

  .playlist-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-wrapper {
      display: flex;
      align-items: flex-start;
      gap: var(--gutter-md);

      @include breakpoint(md) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: var(--gutter-sm);
    }

    p {
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.5;
    }
  }

  .curator-score {
    display: inline-flex;
    align-items: center;
    padding: var(--gutter-xs) var(--gutter);
    border-radius: var(--radius-sm);
    @include shadow-border;

    h4 {
      font-size: 1rem;
      border-right: 1px solid var(--border-color);
      padding-right: var(--gutter-sm);
      margin-right: var(--gutter-sm);
    }

    &-data {
      text-transform: capitalize;
    }
  }
}

.playlist-match {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-sm);
  width: 100%;
}
