@use "globals" as *;

.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--gutter);
    line-height: 1.25;
  }

  a {
    color: var(--primary-text-color);
    text-decoration: underline;
    font-weight: 500;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1.8rem;
  }

  ol,
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gutter);
    padding-left: var(--gutter-md);
    margin-bottom: 1.8rem;

    li {
      p {
        margin: 0;
      }
    }
  }

  ul {
    list-style: disc;

    li {
      &::marker {
        color: var(--secondary-color);
      }
    }
  }

  ol {
    list-style: decimal;

    li {
      &::marker {
        color: var(--secondary-color);
        font-weight: 600;
      }
    }
  }
}
