@use "globals" as *;

ol.stepper {
  display: flex;
  list-style: none;
  justify-content: space-between;
  background: linear-gradient(var(--line-color) 0 0) no-repeat 50% calc((var(--step-size) - var(--line)) / 2) / 100%
    var(--line);
  counter-reset: step;
  margin: 0;
  padding: 0;
  counter-reset: step;
  overflow: hidden;
  transition: var(--time) all var(--curve-fast);
  --default-b: var(--secondary-bg-color);
  --default-c: var(--primary-text-color);
  --active-b: var(--primary-color);
  --active-c: var(--primary-text-color);
  --step-size: 4rem;
  --line: 2px;
  --line-color: var(--border-color);

  @include breakpoint(sm) {
    --step-size: 3rem;
  }

  li {
    position: relative;
    display: grid;
    place-items: center;
    gap: var(--gutter);
    padding-bottom: var(--gutter-lg);
    transition: var(--time) all var(--curve-fast);

    @include breakpoint(sm) {
      padding-bottom: var(--gutter-md);
    }

    &::after {
      content: "";
      position: absolute;
      height: var(--line);
      right: 100%;
      top: calc((var(--step-size) - var(--line)) / 2);
      width: 100vw;
      transition: var(--time-fast) all var(--curve-fast);
    }

    .step-index {
      display: grid;
      place-content: center;
      aspect-ratio: 1;
      height: var(--step-size);
      box-sizing: border-box;
      background: var(--default-b);
      font-size: 1.5rem;
      color: var(--default-c);
      border: 2px solid var(--border-color);
      border-radius: var(--radius-lg);
      z-index: 1;
      transition: var(--time-fast) all var(--curve-fast);

      @include breakpoint(sm) {
        font-size: 1rem;
      }

      &:hover {
        background: var(--secondary-bg-color);
        color: var(--primary-text-color);
        border-color: rgba(var(--primary-text-color-rgb), 0.5);
        transition: var(--time) all var(--curve-fast);
      }

      .icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .step-label {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      font-size: 0.9rem;
      font-weight: 500;
      letter-spacing: 0.02rem;
      opacity: 0.7;

      @include breakpoint(sm) {
        font-size: 0.8rem;
        letter-spacing: 0;
      }
    }

    &.completed,
    &.active {
      .step-label {
        opacity: 1;
      }
    }

    &.completed {
      .step-index {
        background: var(--primary-light-color);
        color: var(--primary-color);
        border-color: rgba(var(--primary-color-rgb), 0.5);
      }
    }

    &.active {
      .step-index {
        background: var(--primary-light-color);
        color: var(--primary-color);
        border-color: rgba(var(--primary-color-rgb), 0.5);
      }

      &::after {
        background: var(--active-b);
        transition: var(--time-fast) all var(--curve-fast);
      }
    }
  }
}

.stepper-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gutter);
  width: 100%;

  @media screen and (min-width: $breakpoint-md) {
    button {
      min-width: 200px;
    }
  }

  @include breakpoint(sm) {
    button {
      flex: 1;
    }
  }

  button:only-child {
    margin-left: auto;
  }
}

.step-content {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-lg);
  background-color: var(--secondary-bg-color);
  overflow: hidden;
}
