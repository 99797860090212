@use "globals" as *;

.team {
  &-member {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(var(--secondary-bg-color-rgb), 0.5);
    border-radius: var(--radius-lg);
    padding: var(--gutter);
    overflow: hidden;
    @include shadow-soft;
    transition: 0.5s all;

    &-image {
      position: relative;
      width: 140px;
      height: 140px;
      max-width: 140px;
      max-height: 140px;
      aspect-ratio: 1 / 1;
      margin-bottom: var(--gutter-md);

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-content {
      width: 100%;
      text-align: center;

      h3 {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 600;
        margin-bottom: var(--gutter-sm);
      }

      span {
        font-size: 1.15rem;
        font-weight: 300;
        opacity: 0.7;
      }
    }

    &-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: 50% 20%;
      background-size: cover;
    }
  }
}
