@use "globals" as *;

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    text-indent: -1rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
    line-height: 1.5;

    &:last-child {
      border-bottom: 0;
    }

    &:before {
      content: "\2022";
      color: var(--primary-color);
      margin-right: 0.5rem;
      font-weight: 600;
    }
  }
}

ul.list-card {
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
  counter-reset: list-number;

  li {
    background-color: rgba(var(--secondary-bg-color-rgb), 0.8);
    border-radius: var(--radius-lg);
    @include shadow-border;
    padding: var(--gutter);

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

ol.list-badges {
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
  counter-reset: list-number;

  li {
    position: relative;
    background-color: rgba(var(--secondary-bg-color-rgb), 0.8);
    border-radius: var(--radius-lg);
    @include shadow-border;
    padding: var(--gutter) var(--gutter) var(--gutter) 4rem;

    &:before {
      position: absolute;
      top: var(--gutter);
      left: 0.75rem;
      counter-increment: list-number;
      content: counter(list-number);
      width: 2.5rem;
      height: 2.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 600;
      background-color: rgba(var(--primary-color-rgb), 0.1);
      border-radius: 50%;
      color: var(--primary-color);
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
