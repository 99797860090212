@use "globals" as *;

#how-it-works {
  .slider {
    padding: 0;
    border-radius: var(--radius) var(--radius) 0 0;
    overflow: hidden;

    .video {
      border-radius: var(--radius) var(--radius) 0 0;
      pointer-events: none;
    }
  }

  .slider-navigation {
    display: flex;
    align-items: center;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: 0 0 var(--radius) var(--radius);
    overflow: hidden;

    @include breakpoint(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      text-align: left;
      flex: 1;
      height: 200px;
      padding: var(--gutter);
      border: 0;
      border-right: 1px solid var(--border-color);
      border-radius: 0;

      &:last-child {
        border-right: 0;
      }

      &.active {
        background-color: rgba(var(--primary-color-rgb), 0.2);

        .index {
          color: var(--primary-color);
          opacity: 1;
          transition: var(--time-fast) all ease-in-out;
        }
      }

      @include breakpoint(md) {
        width: 100%;
        height: auto;
        flex-direction: row;
        border-right: 0;
        border-top: 1px solid var(--border-color);
        padding: 0.75rem var(--gutter-md);

        .button-label {
          display: flex;
          align-items: center;
          gap: var(--gutter);
          width: 100%;
        }
      }

      .index {
        font-size: 4rem;
        position: absolute;
        top: var(--gutter-sm);
        left: var(--gutter);
        opacity: 0.5;
        transition: var(--time-fast) all ease-in-out;

        @include breakpoint(md) {
          position: relative;
          font-size: 2rem;
          top: unset;
          left: unset;
        }
      }

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 6px;
        background-color: rgba(var(--primary-color-rgb), 0.2);
        border: 0;
        border-radius: 0;

        @include breakpoint(md) {
          width: 6px;
          height: 100%;
        }

        &-bar {
          background-color: var(--primary-color);

          @include breakpoint(md) {
            width: 6px;
            height: 0;
          }
        }
      }
    }
  }
}
