@use "globals" as *;

.countdown {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--gutter) var(--gutter-md);
    flex: 1;
    border-right: 1px solid var(--border-color);

    &:last-child {
      border-right: none;
    }

    p {
      display: flex;
      flex-direction: column;
      font-size: 1.8rem;
      line-height: 1;
      font-weight: 600;
      text-align: center;
    }

    span {
      font-size: 0.75rem;
      font-weight: 300;
      text-transform: uppercase;
      opacity: 0.75;
      margin-top: var(--gutter-sm);
      letter-spacing: 0.2rem;
    }
  }

  &-limit {
    color: var(--red-color);
  }
}

.countdown-inline {
  display: inline-flex;
  border: none;
  gap: 0.75rem;
  border-radius: 0;

  .countdown-item {
    flex-direction: column;
    padding: 0;
    border: none;

    p {
      font-size: 1rem;
    }

    span {
      font-size: 0.6rem;
      text-transform: none;
      letter-spacing: 0;
      margin-top: 0.1rem;
    }
  }
}
