@use "globals" as *;

.share-buttons {
  button {
    flex: 1;
    flex-shrink: 0;

    .button-label {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .icon {
      width: 2rem;
      height: 2rem;
    }
  }
}
