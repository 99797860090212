@use "globals" as *;

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter-sm);
}

.tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: rgba(var(--primary-color-rgb), 0.15);
  color: var(--primary-color);
  border-radius: var(--radius-sm);
  padding: var(--gutter-xs) var(--gutter-sm);
  overflow: hidden;

  &-sm {
    font-size: 0.8rem;
  }

  > span {
    padding: var(--gutter-sm);
  }

  &-green {
    background-color: rgba(var(--green-color-rgb), 0.1);
    color: var(--green-color);
  }

  &-red {
    background-color: rgba(var(--red-color-rgb), 0.1);
    color: var(--red-color);
  }

  .btn-clear {
    background-color: transparent;
    color: rgba(var(--primary-color-rgb), 0.5);
    min-width: 40px;
    height: 100%;
    padding: 0;
    border-radius: 0 var(--radius-sm) var(--radius-sm) 0;
    border-left: 1px solid rgba(var(--primary-color-rgb), 0.1);

    &:hover {
      background-color: rgba(var(--primary-color-rgb), 0.2);
      color: var(--primary-color);
    }
  }
}
