@use "globals" as *;

.field,
label {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.02rem;
  margin-bottom: var(--gutter-sm);
}

.field-input-container {
  --marginal-size: 3rem;

  h5 {
    display: inline-flex;
    align-items: center;
    gap: var(--gutter-sm);
    font-size: 1.25rem;
    margin-bottom: var(--gutter-sm);
  }

  label {
    display: block;
    font-size: 1rem;
    color: var(--secondary-text-color);
    font-weight: 500;
    margin-bottom: var(--gutter-sm);
  }

  .input-wrapper {
    position: relative;
    display: flex;
    border: 1px solid var(--border-color);
    background-color: var(--input-color);
    border-radius: var(--radius);
    overflow: hidden;
    transition: var(--time) ease-in-out;

    &:focus-within {
      border-color: var(--primary-color);
      transition: var(--time) ease-in-out;
    }

    input {
      flex: 1 auto;
      height: var(--input-height);

      &.invalid {
        border-color: var(--red-color);
      }
    }

    .has-prefix {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .has-suffix {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--gutter-sm) 0.75rem;
    background-color: rgba(var(--primary-bg-color-rgb), 0.5);
    color: rgba(var(--primary-text-color-rgb), 0.5);
    flex-grow: 1;
    flex-shrink: 0;

    &-left {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
      border-right: 1px solid var(--border-color);
    }

    &-right {
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
      border-left: 1px solid var(--border-color);
      padding: 0;
    }

    &-icon {
      font-size: 1.15rem;
      width: var(--marginal-size);
    }

    &-prefix {
      font-size: 1rem;
    }

    & ~ input {
      padding: var(--gutter-sm) 0.75rem;
    }
  }

  .select {
    width: 100%;
  }

  &.field-select,
  &.field-time,
  &.field-slider {
    .input-wrapper {
      background-color: transparent;

      &:focus-within {
        border-color: transparent;
      }
    }
  }

  &.field-slider,
  &.field-time {
    .input-wrapper {
      border: none;
    }
  }
}

.PhoneInputCountry {
  background-color: var(--input-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gutter-sm) 0.75rem;
  margin: 0 !important;
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-right: 1px solid var(--border-color);

  ~ input[type="tel"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.field-error {
  .input-wrapper {
    border-color: var(--red-color);
  }
}

.field-icon-before {
  input,
  input[type="text"],
  input[type="number"],
  input[type="email"] {
    padding-left: var(--marginal-size);
  }
}

.field-icon-after {
  input,
  input[type="text"],
  input[type="number"],
  input[type="email"] {
    padding-right: var(--marginal-size);
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
}

.form-inline {
  display: flex;
  align-items: center;

  .field-input-container {
    flex: 1;
  }

  .input-wrapper,
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="date"],
  input[type="month"],
  input[type="tel"],
  input[type="email"],
  input[type="url"] {
    border-radius: var(--radius) 0 0 var(--radius);
  }

  .button {
    border-radius: 0 var(--radius) var(--radius) 0;
    height: 46px;
    margin-left: -2px;
  }
}

.error-message {
  width: 100%;
  color: var(--red-color);
  font-weight: 500;
  margin-top: var(--gutter-sm);
}

.info-message {
  width: 100%;
  color: var(--primary-color);
  font-weight: 500;
  margin-top: var(--gutter);
}

.field-phone {
  .PhoneInput {
    width: 100%;
  }
}

.PhoneInput {
  border-radius: var(--radius);
  border: 1px solid var(--border-color);
}

.light {
  .card {
    --input-color: var(--primary-bg-color);
  }
}
