@use "globals" as *;

.info {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  overflow: hidden;
  border-bottom: 1px solid var(--border-color);

  &:first-child {
    padding-top: 0;
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  @include breakpoint(sm) {
    flex-direction: column;
    align-items: flex-start;

    .info-label {
      margin-bottom: var(--gutter-sm);
    }

    .info-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-label {
    display: flex;
    align-items: center;
    gap: var(--gutter-sm);
    width: 50%;
    opacity: 0.9;

    @include breakpoint(sm) {
      width: 100%;
    }
  }

  &-value {
    display: flex;
    align-items: center;
    gap: var(--gutter-sm);
    width: 50%;
    font-weight: 500;
    max-width: 100%;

    @include breakpoint(sm) {
      width: 100%;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include breakpoint(sm) {
        white-space: normal;
      }
    }
  }

  &-small {
    padding: 0.75rem 0;
  }
}

.info-vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  border: 0;

  .info-label {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: var(--gutter-sm);
  }
}
