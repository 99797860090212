@use "globals" as *;

.button-group {
  display: flex;
  align-items: center;
  justify-content: center;

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  .button {
    border-radius: 0;
    border-right-width: 0;
    margin: 0;

    &:first-child {
      border-radius: var(--radius) 0 0 var(--radius);
    }

    &:last-child {
      border-radius: 0 var(--radius) var(--radius) 0;
      border-right-width: 1px;
    }
  }

  .button-outline {
    &.active {
      background-color: var(--invert-color);
      color: var(--primary-bg-color);
    }
  }

  .button-outline-primary {
    &.active {
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }

  @include breakpoint(md) {
    width: 100%;
    flex-direction: column;

    .button {
      width: 100%;
      border-radius: 0;
      border-right-width: 1px;
      border-bottom-width: 0;

      &:first-child {
        border-radius: var(--radius) var(--radius) 0 0;
      }

      &:last-child {
        border-radius: 0 0 var(--radius) var(--radius);
        border-bottom-width: 1px;
      }
    }
  }
}
