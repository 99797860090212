@use "globals" as *;

.card-features {
  gap: var(--gutter);
  padding: var(--gutter-sm);
  border-radius: var(--radius-sm);

  .card-image {
    height: 260px;
    border-radius: calc(var(--radius-sm) - var(--gutter-xs));
  }

  .overlay-title {
    font-size: 2rem;
    text-wrap: balance;

    @include breakpoint(md) {
      font-size: 1.8rem;
    }
  }

  .card-content {
    height: 100%;
    text-align: left;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--gutter-xs);
    height: 100%;

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
    }
  }

  .feature-item {
    display: flex;
    width: 100%;
    gap: 0.75rem;
    padding: 0.75rem var(--gutter-sm);
    border-radius: var(--radius-sm);
  }
}
