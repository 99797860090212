@use "globals" as *;

.card-playlist {
  position: relative;
  border-radius: var(--radius);
  background-color: var(--secondary-bg-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  padding: var(--card-padding);
  border: 1px solid var(--border-color);
  transition: var(--time-fast) all var(--curve-fast);
  --card-padding: 0.5rem;
  --progress-size: 8px;

  &:hover {
    background-color: rgba(var(--primary-text-color-rgb), 0.1);
    transition: var(--time-fast) all var(--curve-fast);

    .card-image {
      border-radius: var(--radius-lg);
      transform: scale(0.9);
    }
  }

  &.star {
    padding: var(--card-padding);
    background: linear-gradient(to bottom, rgba(var(--gold-color-rgb), 0.9), rgba(var(--gold-color-rgb), 0));

    .card-match-progress .progress {
      background-color: rgba(var(--gold-color-rgb), 0.5);

      .progress-bar {
        background-color: rgba(var(--gold-color-rgb), 0.95);
      }
    }
  }

  &.featured {
    padding: var(--card-padding);
    background: linear-gradient(to bottom, rgba(var(--theme-primary-color-rgb), 0.9), rgba(var(--theme-primary-color-rgb), 0));

    .card-match-progress .progress {
      background-color: rgba(var(--theme-primary-color-rgb), 0.5);

      .progress-bar {
        background-color: rgba(var(--theme-primary-color-rgb), 0.95);
      }
    }
  }

  .card-image {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    border-radius: calc(var(--radius) - var(--card-padding));
    transition: var(--time-fast) all var(--curve-fast);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--radius) var(--radius) 0 0;
      object-fit: cover;
    }
  }

  .card-match-progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: var(--progress-size);
    color: var(--white-color);
    z-index: 30;

    span {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 500;
      text-align: right;
      padding: var(--gutter);
    }

    .progress {
      background-color: rgba(var(--white-color-rgb), 0.5);
      border: 0;
      border-radius: 0;
      height: var(--progress-size);
      backdrop-filter: blur(8px);

      &-bar {
        background: var(--white-color);
        height: 100%;
      }
    }
  }

  .score {
    font-size: 1rem;
    line-height: 1;
    font-weight: 600;
    margin: 0;
  }

  .score-label {
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: 300;
    opacity: 0.7;
    letter-spacing: 0.02rem;
  }

  .placeholder {
    border: 0;
  }
}

.pushed {
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gutter-sm);
    transition: var(--time-fast) var(--curve-fast);

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.5rem;
      height: 3.5rem;
      padding: 0.25rem;
      border-radius: 50%;
      background-color: var(--green-color);
    }
  }

  .card-header,
  .card-image {
    opacity: 0.2;
  }

  &:hover {
    .card-header,
    .card-image {
      opacity: 1;
      transition: var(--time-fast) var(--curve-fast);
    }

    .pushed-overlay {
      opacity: 0;
      transition: var(--time-fast) var(--curve-fast);
    }
  }
}
