@use "globals" as *;

.section-title {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @include breakpoint(sm) {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  .badge {
    font-size: 1.2rem;
    padding: var(--gutter-sm) var(--gutter-md);
    border-radius: 900px;
    margin-bottom: var(--gutter);

    @include breakpoint(sm) {
      font-size: 1rem;
    }

    .icon {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .emoji {
    display: block;
    font-size: 3.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.2;
    text-wrap: balance;
  }

  h1,
  h2 {
    letter-spacing: 0.04rem;
  }

  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0.025rem;
  }

  h1 {
    font-size: clamp(2.4rem, 4vw, 6rem);
  }

  h3 {
    font-size: clamp(1.8rem, 3vw, 5rem);
  }

  &-center {
    text-align: center;

    .subtitle {
      margin: var(--gutter-sm) auto 0 auto;
    }
  }

  .subtitle {
    display: block;
    font-size: 1.5rem;
    max-width: 75%;
    font-weight: 300;
    line-height: 1.5;
    opacity: 0.8;
    text-wrap: balance;

    @include breakpoint(sm) {
      font-size: 1.2rem;
      max-width: 100%;
    }
  }
}
