@use "globals" as *;

.verified {
  svg {
    width: 28px;
    color: var(--gold-color);
  }
}

.avatar-star {
  background: linear-gradient(to bottom, rgba(var(--gold-color-rgb), 0.2), rgba(var(--gold-color-rgb), 0.8));
  overflow: visible;
  --avatar-gap: 0.6rem;

  img {
    width: calc(var(--avatar-size) - var(--avatar-gap));
    height: calc(var(--avatar-size) - var(--avatar-gap));
    top: calc(var(--avatar-gap) / 2);
    left: calc(var(--avatar-gap) / 2);
    border-radius: 50%;
  }

  .verified {
    position: absolute;
    right: 0.6rem;
    bottom: 0.1rem;
    z-index: 20;

    svg {
      width: 24px;
    }
  }

  .placeholder {
    .icon svg {
      color: var(--gold-color);
    }
  }
}

.profile-sidebar-header {
  .verified {
    svg {
      width: 16px;
    }
  }
}

.chat-header,
.chat-list-item {
  .avatar-star {
    --avatar-gap: 0.3rem;

    .verified {
      right: 0;

      svg {
        width: 16px;
      }
    }
  }
}

.curator-card-star {
  background-color: rgba(var(--gold-color-rgb), 0.1);
  border: 1px solid rgba(var(--gold-color-rgb), 0.2);

  &:hover {
    border-color: rgba(var(--gold-color-rgb), 0.3);
  }
}
