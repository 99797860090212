@use "globals" as *;

.piano {
  width: 100%;
}

.piano-theme {
  --highlight-color: rgba(var(--primary-color-rgb), 0.9);

  .ReactPiano__Key--natural {
    border-radius: 0 0 var(--radius) var(--radius);

    &:first-child {
      border-radius: var(--radius-lg) 0 var(--radius) var(--radius-lg);
    }

    &:last-child {
      border-radius: 0 var(--radius-lg) var(--radius-lg) var(--radius);
    }
  }

  .ReactPiano__Key--accidental {
    background-color: #333;
    border-radius: 0 0 var(--radius) var(--radius);
  }

  .ReactPiano__Key--active.ReactPiano__Key--accidental {
    background: var(--highlight-color);
  }

  .ReactPiano__Key--active {
    background: var(--highlight-color);
  }

  .ReactPiano__NoteLabel {
    font-size: 0.85rem;
    font-weight: 500;
    opacity: 0.8;
  }
}
