@use "globals" as *;

.thumbnail {
  &-image {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: var(--radius-lg);
    overflow: hidden;
    box-shadow: 0 0 0 1px var(--border-color);

    .placeholder {
      border: 0;
    }
  }

  &-bg {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .badge {
      position: absolute;
      top: var(--gutter);
      right: var(--gutter);
    }

    .overlay {
      background: linear-gradient(to top, rgba(var(--overlay-color-rgb), 0.9), rgba(var(--overlay-color-rgb), 0));
    }
  }

  &-inner {
    position: relative;
    color: var(--white-color);
    padding: var(--gutter) var(--gutter-md);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-weight: 600;
    height: 100%;
    text-decoration: none;

    .thumbnail-title {
      font-size: 1.4rem;
      font-weight: 600;
      max-width: 90%;
      text-wrap: balance;
      text-align: left;
      font-family: var(--font-family-default);
      margin: 0 0 var(--gutter-xs) 0;
      color: var(--white-color);
    }

    .thumbnail-album {
      font-size: 1rem;
      margin: 0;
    }
  }

  &-link {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &-featured-icon {
    max-width: 60px;
    max-height: 60px;
    position: absolute;
    top: var(--gutter);
    right: var(--gutter);
  }
}

.thumbnail-wrapper.thumbnail-bg-auto {
  width: auto;
  height: auto;
}
