@use "globals" as *;

.home-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 0 !important;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 3rem var(--gutter) 0 var(--gutter);

    @include breakpoint(sm) {
      padding: var(--gutter-lg) var(--gutter) 0 var(--gutter);
    }
  }

  &-scroll-down {
    font-weight: 500;
    color: var(--primary-text-color);
    opacity: 0.75;
    cursor: pointer;
  }

  &-score {
    .rating {
      --star-size: 1rem;
    }
  }

  h1 {
    font-size: 3vw;
    max-width: 28ch;
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.02rem;
    text-wrap: balance;
    color: var(--primary-text-color);

    @include breakpoint(md) {
      font-size: 3rem;
    }

    @include breakpoint(sm) {
      font-size: 2rem;
      margin-bottom: var(--gutter);
    }
  }

  h2 {
    font-size: 1.5rem;
    max-width: 48ch;
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
    opacity: 0.75;
    font-family: var(--font-family-default);
    color: var(--primary-text-color);
    margin-bottom: var(--gutter-md);

    @include breakpoint(sm) {
      font-size: 1rem;
    }
  }
}

.home-grid-wrapper {
  position: relative;
  z-index: 0;
  pointer-events: none;

  .overlay {
    background-color: transparent;
    z-index: 20;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 160px;
      height: 100%;
      z-index: 1;
    }

    &:before {
      left: 0;
      background: linear-gradient(
        to left,
        rgba(var(--primary-bg-color-rgb), 0) 0%,
        rgba(var(--primary-bg-color-rgb), 1) 90%
      );
    }

    &:after {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(var(--primary-bg-color-rgb), 1) 10%,
        rgba(var(--primary-bg-color-rgb), 0) 100%
      );
    }
  }
}

.home-grid {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gutter-sm);
  mask-image: linear-gradient(to top, var(--primary-bg-color), transparent 100%);
  margin-bottom: -0.5rem;

  .marquee {
    gap: var(--gutter-sm);

    .rfm-marquee,
    .rfm-initial-child-container {
      gap: var(--gutter-sm);
    }
  }
}
