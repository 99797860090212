@use "globals" as *;

.mockup {
  max-width: 100%;
  border-radius: var(--radius);
  overflow: hidden;
  @include shadow-border;
  overflow: hidden;

  .header {
    background: rgba(var(--secondary-bg-color-rgb), 0.2);
    padding: var(--gutter);

    .addressbar {
      display: flex;

      .favicon {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: var(--radius-sm);
        overflow: hidden;
        flex-shrink: 0;
        margin-right: var(--gutter-sm);
        @include shadow-border;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .address {
        color: var(--primary-text-color);
        background: var(--secondary-bg-color);
        border-radius: var(--radius-sm);
        height: 32px;
        display: flex;
        align-items: center;
        flex-grow: 1;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 var(--gutter);
        @include shadow-border;
      }

      .address-placeholder {
        width: 70%;
        height: 1rem;
        background-color: rgba(var(--primary-text-color-rgb), 0.1);
        border-radius: var(--radius-sm);
      }
    }
  }

  &-alt {
    .header {
      background: var(--secondary-bg-color);

      .addressbar .address {
        background: rgba(var(--primary-bg-color-rgb), 0.4);
      }
    }
  }
}

.slider-mockup {
  .caption {
    font-size: 4rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: var(--white-color);
    font-weight: 600;
    text-align: left;
    padding: 3% 8%;
    margin: 0;
    z-index: 20;

    @include breakpoint(sm) {
      font-size: 2rem;
    }
  }
}
