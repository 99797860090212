@use "globals" as *;

.pac-container {
  background-color: var(--secondary-bg-color) !important;
  border: 0 !important;
  border-radius: var(--radius) !important;
  box-shadow: 0 1px 3px rgba(var(--shadow-color-rgb), 0.01) !important;
  z-index: 100 !important;
  pointer-events: all !important;

  &:after {
    display: none;
  }

  .pac-item {
    color: var(--primary-text-color);
    border: 0;
    display: flex;
    align-items: center;
    min-height: var(--input-height) !important;

    &.pac-item-selected {
      background-color: rgba(var(--primary-color-rgb), 0.1) !important;
    }

    &:hover {
      background-color: rgba(var(--primary-color-rgb), 0.1) !important;
    }

    .pac-item-query {
      color: var(--primary-text-color);
    }
  }
}

.map-info-window {
  color: var(--primary-text-color);
}

.custom-cluster-label {
  background-color: #ff6347;
  color: var(--white-color);
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.custom-cluster-label:hover {
  background-color: #ff4500;
  cursor: pointer;
}

.theme-dark {
  .map-info-window {
    color: var(--primary-bg-color);
  }
}
