@use "globals" as *;

:root {
  --track-cover-size: 64px;
  --track-radius: var(--radius-sm);

  @include breakpoint(sm) {
    --track-cover-size: 48px;
    --track-radius: var(--radius-sm);
  }
}

.track {
  &-item {
    &.xs {
      --track-cover-size: 32px;
      --track-radius: var(--radius-xs);
    }

    &.sm {
      --track-cover-size: 48px;
      --track-radius: var(--radius-xs);
    }

    &.md {
      --track-cover-size: 64px;
    }

    &.lg {
      --track-cover-size: 80px;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: var(--gutter);

    @include breakpoint(lg) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include breakpoint(md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: var(--gutter-sm);
    }

    @include breakpoint(xs) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 0.15rem;

    h4 {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
    }

    p {
      font-size: 0.85rem;
      line-height: 1;
      opacity: 0.8;
      margin: 0;
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: var(--radius);
    padding: var(--gutter-sm);
    gap: var(--gutter);
    border: 1px solid var(--border-color);

    @include breakpoint(sm) {
      align-items: center;
      padding: var(--gutter-sm);
    }
  }

  &-cover {
    position: relative;
    width: var(--track-cover-size);
    min-width: var(--track-cover-size);
    height: var(--track-cover-size);
    min-height: var(--track-cover-size);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--track-radius);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-thumbnail {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--track-radius);
  }
}
