@use "globals" as *;

.paywall {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--gutter-md);
  text-align: center;
  width: 100%;
  font-weight: 600;
  margin: var(--gutter-md) 0;
  padding: 4rem var(--gutter-md);
  background: rgba(var(--secondary-bg-color-rgb), 0.25);
  border: 1px dashed var(--border-color);
  border-radius: var(--radius);

  i,
  svg {
    opacity: 0.25;
  }

  i,
  svg,
  .emoji {
    display: block;
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  .emoji {
    display: block;
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(var(--primary-text-color-rgb), 0.9);
    margin-bottom: 0;
    text-wrap: balance;
  }

  .button {
    min-width: 180px;
    max-width: 100%;
  }
}
