@use "globals" as *;

.copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--secondary-bg-color);
  padding: var(--gutter-md) var(--gutter);
  color: var(--primary-text-color);
  font-size: 0.8rem;
  border-top: 1px solid var(--border-color);
  opacity: 0.5;

  @include breakpoint(sm) {
    flex-direction: column;
    gap: var(--gutter-md);

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      gap: var(--gutter);
    }
  }

  > div:not(.credits) > * {
    &:after {
      display: inline;
      content: "|";
      margin: 0 var(--gutter-sm);
      opacity: 0.5;

      @include breakpoint(sm) {
        display: none;
      }
    }

    &:last-child::after {
      display: none;
    }
  }
}

.credits {
  svg {
    width: 24px;
    height: 24px;
    fill: var(--primary-text-color);
  }
}
