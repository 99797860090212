@use "globals" as *;

.dropdown {
  &-menu-container {
    background: var(--secondary-bg-color);
    border-radius: var(--radius);
    border: 1px solid var(--border-color);
    @include shadow-soft;
  }

  &-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.2rem;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      width: 100%;
      border-radius: var(--radius-sm);
      padding: 0.5rem 0.75rem;
      font-size: 0.85rem;
      cursor: pointer;
      transition: var(--time-faster) all ease-out;

      &:hover,
      &:focus,
      &[data-highlighted] {
        background-color: rgba(var(--primary-text-color-rgb), 0.1);
        transition: var(--time-faster) all ease-out;
      }

      a {
        display: flex;
        align-items: center;
        gap: var(--gutter-sm);
      }

      a,
      button {
        width: 100%;
        font-weight: 500;
      }
    }

    &-cta {
      width: 100%;
      padding: var(--gutter-sm);
    }
  }

  &-menu-toggle {
    display: flex;
    align-items: center;
  }
}

[data-radix-popper-content-wrapper] {
  z-index: 1000 !important;
}
