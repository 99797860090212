@use "globals" as *;

.promo-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gutter-lg);
  padding: var(--gutter-lg) var(--gutter-md);
  border-radius: var(--radius-lg);
  @include shadow-border;
  overflow: hidden;

  &:hover {
    &:before {
      opacity: 0.4;
      transition: var(--time-slow) all var(--curve-fast);
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    opacity: 0.1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    transition: var(--time-slow) all var(--curve-fast);
    animation: pulse-gradient 10s ease-in-out infinite;
  }
}

@keyframes pulse-gradient {
  0% {
    opacity: 0.1;
    transform: scale(1) rotate(0deg);
  }

  50% {
    opacity: 0.5;
    transform: scale(2) rotate(5deg);
  }

  100% {
    opacity: 0.1;
    transform: scale(1) rotate(0deg);
  }
}

.ticket {
  position: relative;
  background: linear-gradient(to right, var(--color1), var(--color2), var(--color3), var(--color4));
  padding: var(--gutter-sm);
  border-radius: var(--radius-lg);
}

.ticket-copy {
  text-align: center;
  border-top: 1px dashed var(--border-color);
  font-family: var(--font-family-mono);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-top: var(--gutter);
  margin-top: var(--gutter);
}

.ticket-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--background);
  padding: var(--gutter) var(--gutter-lg);
  border-radius: calc(var(--radius-lg) - var(--gutter-sm));
}

.promo-card-primary {
  background-color: rgba(var(--primary-color-rgb), 0.1);

  &:before {
    background: radial-gradient(circle, rgba(var(--primary-color-rgb), 0.1), rgba(var(--primary-color-rgb), 0.9));
  }

  .ticket {
    --background: rgba(var(--primary-bg-color-rgb), 0.9);
    --color1: rgba(var(--primary-color-rgb), 1);
    --color2: rgba(var(--primary-color-rgb), 0.8);
    --color3: rgba(var(--primary-color-rgb), 0.7);
    --color4: rgba(var(--primary-color-rgb), 0.4);
  }
}

.promo-card-gold {
  background-color: rgba(var(--gold-color-rgb), 0.1);

  &:before {
    background: radial-gradient(circle, rgba(var(--gold-color-rgb), 0.1), rgba(var(--gold-color-rgb), 0.9));
  }

  .ticket {
    --background: rgba(var(--primary-bg-color-rgb), 0.9);
    --color1: rgba(var(--gold-color-rgb), 1);
    --color2: rgba(var(--gold-color-rgb), 0.8);
    --color3: rgba(var(--gold-color-rgb), 0.7);
    --color4: rgba(var(--gold-color-rgb), 0.4);
  }
}
