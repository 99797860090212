@use "globals" as *;

.dashboard-section {
  margin-bottom: var(--gutter-xl);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--gutter);

    @include breakpoint(md) {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--gutter-sm);
    }

    h2 {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dashboard-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: var(--gutter);
  background-color: transparent;
  border: 1px solid var(--border-color);
  overflow: hidden;

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h4 {
      display: flex;
      align-items: center;
      gap: var(--gutter-sm);
      font-size: 1.2rem;
      font-weight: 400;
      opacity: 0.8;
      text-wrap: balance;
      margin-bottom: var(--gutter-xl);
      letter-spacing: -0.01rem;
    }

    .card-value {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .card-chart {
    position: relative;
    display: flex;
    height: 100%;
    flex-grow: 1;
    min-width: 160px;
    align-items: flex-end;
    justify-content: flex-end;
    mask-image: linear-gradient(to bottom, var(--primary-bg-color), transparent 90%);
  }

  .card-tooltip {
    position: absolute;
    top: var(--gutter);
    right: var(--gutter);
    z-index: 20;
  }
}
