@use "globals" as *;

.select {
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--time) all ease-in-out;

  &:hover {
    transition: var(--time) all ease-in-out;
  }

  &__menu-portal {
    z-index: 100 !important;
  }

  &__control {
    background-color: var(--input-color) !important;
    border: transparent !important;
    min-height: var(--input-height) !important;
    border-radius: var(--radius) !important;
    border: 1px solid var(--border-color) !important;

    &--is-focused {
      box-shadow: 0 0 0 1px var(--primary-color) !important;
    }
  }

  &__multi-value {
    background-color: rgba(var(--theme-primary-color-rgb), 0.12) !important;
    color: var(--theme-primary-color) !important;
    height: 30px;
    border-radius: var(--radius-sm) !important;

    &__label {
      font-size: 0.9rem !important;
      display: flex;
      align-items: center;
      color: var(--theme-primary-color) !important;
      font-weight: 600;
      padding: 0 var(--gutter-sm) !important;
    }

    &__remove {
      color: var(--theme-primary-color) !important;
      background-color: transparent;
      border-left: 1px solid rgba(var(--theme-primary-color-rgb), 0.2);
      border-radius: 0 var(--radius-sm) var(--radius-sm) 0 !important;
      padding: 0 var(--gutter-sm) !important;
      text-align: center;
      transition: var(--time) all ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: rgba(var(--theme-primary-color-rgb), 0.2) !important;
        transition: var(--time) all ease-in-out;
      }
    }
  }

  &__single-value {
    position: absolute;
    color: var(--primary-text-color) !important;
  }

  &__input {
    color: var(--primary-text-color) !important;
    min-width: 100% !important;

    input {
      color: var(--primary-text-color) !important;
    }
  }

  &__input-container {
    display: flex !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &__placeholder {
    position: absolute;
    pointer-events: none;
  }

  &__value-container {
    display: flex !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: var(--gutter-sm) !important;
  }

  &__clear-indicator,
  &__dropdown-indicator {
    color: rgba(var(--primary-text-color-rgb), 0.5) !important;
  }

  &__indicator-separator {
    background-color: var(--border-color) !important;
  }

  &-value__label {
    color: var(--primary-text-color) !important;
  }

  &__menu {
    background-color: var(--input-color) !important;
    border-radius: var(--radius) !important;
    overflow: hidden !important;
    margin-bottom: 0;
    z-index: 100 !important;
  }

  &__menu-list {
    padding: 0 !important;
    border-radius: var(--radius);
  }

  &__option {
    background-color: var(--secondary-bg-color) !important;
    color: var(--primary-text-color);
    overflow: hidden;
    transition: var(--time) all ease-in-out;

    &:first-child {
      border-top-left-radius: var(--radius) !important;
      border-top-right-radius: var(--radius) !important;
    }

    &:last-child {
      border-bottom-left-radius: var(--radius) !important;
      border-bottom-right-radius: var(--radius) !important;
    }

    &:hover {
      background-color: rgba(var(--theme-primary-color-rgb), 0.1) !important;
      transition: var(--time) all ease-in-out;
    }
  }

  &__option--is-focused,
  &__option--is-selected {
    color: var(--primary-text-color) !important;
    background-color: rgba(var(--theme-primary-color-rgb), 0.3) !important;
  }
}

.input-wrapper {
  .select__control {
    border: 0 !important;
  }
}

.ReactModal__Body--open {
  .select {
    &__menu-portal {
      z-index: 100 !important;
    }
  }
}

.field-error {
  .select__control {
    box-shadow: 0 0 0 1px var(--red-color) !important;
  }
}
