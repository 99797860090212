@use "globals" as *;

.language-dropdown {
  &:hover {
    background-color: rgba(var(--white-color-rgb), 0.1);
  }

  .select__indicators {
    display: none;
  }

  .select__value-container {
    padding: 0 var(--gutter) !important;
  }

  .select__control {
    background-color: transparent !important;
    border: none !important;
  }

  .select__single-value {
    position: relative !important;
  }
}

.language-dropdown {
  border-radius: var(--radius-sm) !important;

  .select__control {
    min-height: 40px !important;
  }

  .select__value-container {
    padding: 0 0.75rem !important;
  }

  &:hover {
    background-color: rgba(var(--primary-text-color-rgb), 0.1);
  }
}
