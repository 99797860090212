$breakpoint-xs: 575px;
$breakpoint-sm: 767px;
$breakpoint-md: 959px;
$breakpoint-lg: 1279px;
$breakpoint-xl: 1439px;
$breakpoint-2xl: 1540px;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  } @else if $class == xxl {
    @media (min-width: $breakpoint-2xl) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}
