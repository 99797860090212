@use "globals" as *;

.mentions {
  &-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: var(--gutter-lg);

    @include breakpoint(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--gutter);
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border-radius: var(--radius);
      transition: var(--time) var(--curve-fast);

      &:hover {
        opacity: 1;
        transition: var(--time) var(--curve-fast);
      }

      &-image {
        position: relative;
        width: 180px;
        height: 80px;
        padding: 0.75rem;
        margin: 0 auto;
        border-radius: var(--radius);
        @include shadow-border;

        @include breakpoint(sm) {
          width: 100%;
          height: 60px;
        }

        img {
          position: absolute;
          top: var(--gutter);
          left: var(--gutter);
          width: calc(100% - 2rem);
          height: calc(100% - 2rem);
          object-fit: contain;
          object-position: 50% 50%;
        }
      }

      h5 {
        font-size: 1.2rem;
        font-weight: 500;
        margin: var(--gutter) 0 0 0;

        @include breakpoint(sm) {
          font-size: 1rem;
          margin: var(--gutter-sm) 0 0 0;
        }
      }
    }
  }
}

.dark {
  .invert-logo {
    img,
    svg {
      filter: grayscale(100%) invert(100%);
      -webkit-filter: grayscale(100%) invert(100%);
    }
  }
}
