@use "globals" as *;

.tab-header {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  color: var(--primary-color);

  button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    gap: var(--gutter-xs);
    background-color: transparent;
    color: var(--primary-text-color);
    transition: var(--time) var(--curve-fast);

    @include breakpoint(sm) {
      font-size: 0.8rem;
    }

    &.active {
      color: var(--theme-primary-color);
      transition: var(--time) var(--curve-fast);
    }
  }
}

.tab-content {
  padding: var(--gutter);

  @include breakpoint(sm) {
    padding: var(--gutter);
  }
}

.tab-hover {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  background-color: rgba(var(--theme-primary-color-rgb), 0.1);
  border-radius: var(--radius);
  z-index: 10;
  pointer-events: none;
}

.tab-indicator {
  position: absolute;
  border-radius: var(--radius-lg);
  background: transparent;
  height: 4px;
  left: 0;
  bottom: 0;
  background-color: var(--theme-primary-color);
  z-index: 10;
}

.tab-inline {
  display: inline-flex;
  width: 100%;

  button {
    flex: 1;
  }
}

.tab-toggle {
  background-color: var(--secondary-bg-color);
  padding: var(--gutter-xs);
  gap: var(--gutter-xs);
  border-radius: var(--radius);

  button {
    border-radius: var(--radius-sm);

    &:hover {
      background: rgba(var(--primary-text-color-rgb), 0.05);
    }

    &.active {
      background: rgba(var(--theme-primary-color-rgb), 0.1);
    }
  }

  .tab-hover {
    border-radius: var(--radius-sm);
  }
}

.tab-gold {
  button.active {
    color: var(--gold-color);
    background-color: rgba(var(--gold-color-rgb), 0.1);
  }
}

.tab-light {
  button.active {
    color: var(--primary-text-color);
    background-color: rgba(var(--primary-text-color-rgb), 0.1);
  }
}

.tab-col-4 {
  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gutter-xs);
  }
}
