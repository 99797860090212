@use "globals" as *;

.card-release {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  overflow: hidden;
  padding: var(--gutter-sm);
  @include shadow-border;

  .card-image {
    border-radius: var(--radius-sm);
    overflow: hidden;
  }

  .card-content {
    h4 {
      font-size: 1.25rem;
      line-height: 1.2;
      margin: var(--gutter-sm) 0 var(--gutter-xs) 0;
    }

    p {
      font-size: 0.9rem;
      font-weight: 300;
    }

    span {
      font-size: 0.85rem;
      font-weight: 500;
    }
  }
}
