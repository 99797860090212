@use "globals" as *;

.playlist-cards {
  display: grid;
  grid-gap: var(--gutter-md);
  grid-template-columns: repeat(3, 1fr);

  @media screen and (min-width: $breakpoint-xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gutter);
  }

  @include breakpoint(sm) {
    grid-template-columns: 1fr;
  }

  .playlist-card-item {
    display: flex;
    height: 100%;
    width: 100%;
  }
}

.playlist-cover {
  position: relative;
  aspect-ratio: 1 / 1;
  border-radius: var(--radius);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: var(--playlist-cover-size);
  height: var(--playlist-cover-size);
  max-width: var(--playlist-cover-size);
  max-height: var(--playlist-cover-size);
  overflow: hidden;
  --playlist-cover-size: 100%;

  @include breakpoint(sm) {
    border-radius: var(--radius-sm);
  }
}
