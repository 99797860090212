@use "globals" as *;

.podium {
  display: grid;
  grid-auto-flow: column dense;
  gap: 0.5rem;
  margin-top: 2rem;
  justify-content: center;
  justify-items: center;
  align-content: flex-end;
  align-items: flex-end;
  border-bottom: 1px solid var(--border-color);
  height: 250px;

  &-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-1 {
      .podium-step-col {
        background-color: rgba(var(--gold-color-rgb), 0.3);
        border-color: rgba(var(--gold-color-rgb), 1);

        .podium-index {
          color: var(--gold-color);
          opacity: 1;
        }
      }
    }

    &-2 {
      .podium-step-col {
        background-color: rgba(var(--silver-color-rgb), 0.3);
        border-color: rgba(var(--silver-color-rgb), 1);

        .podium-index {
          color: var(--silver-color);
          opacity: 1;
        }
      }
    }

    &-3 {
      .podium-step-col {
        background-color: rgba(var(--bronze-color-rgb), 0.3);
        border-color: rgba(var(--bronze-color-rgb), 1);

        .podium-index {
          color: var(--bronze-color);
          opacity: 1;
        }
      }
    }
  }

  &-step-col {
    width: 4rem;
    align-items: center;
    justify-content: center;
    display: flex;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-color: var(--primary-color);
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    background-color: rgba(var(--primary-color-rgb), 0.1);
    border-color: rgba(var(--primary-color-rgb), 0.3);
    margin-bottom: -1px;
  }

  &-avatar {
    align-self: center;
    margin-bottom: var(--gutter-xs);

    img {
      width: 2.75rem;
      height: 2.75rem;
      overflow: hidden;
      border-radius: 50%;
    }
  }

  &-index {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
    align-self: flex-end;
    opacity: 0.7;
  }
}
