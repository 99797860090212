@use "globals" as *;

.analysis-process {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include breakpoint(md) {
    flex-direction: column;
  }

  > div {
    flex: 1;
    width: 33%;

    @include breakpoint(md) {
      width: 100%;
    }
  }
}

.analysis-cover-grid {
  display: grid;
  grid-gap: 1rem;
  background: var(--primary-bg-color);
  grid-template-columns: repeat(3, 1fr);
  transform: translateX(0%) translateY(20%) rotateX(45deg) rotateZ(45deg);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000;

  @include breakpoint(md) {
    transform: translateX(0%) translateY(0%) rotateX(0deg) rotateZ(0deg);
  }
}

.analysis-doc {
  @include breakpoint(md) {
    margin: 100px 0;
  }

  svg {
    display: block;
    fill: rgba(var(--primary-color-rgb), 0.7);
    max-width: 120px;
    margin: 0 auto;
    padding: 1rem;
    background: var(--primary-bg-color);
    transition: 0.5s all;

    &:hover {
      fill: var(--primary-color);
      transform: translateY(-1rem);
      transition: 0.5s all;
    }
  }
}

.analysis-grid {
  position: relative;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 50px);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: translateY(-15%);
  perspective: 1000;

  > div {
    &:first-child {
      transform: translateX(0) translateZ(0);

      &:hover {
        transform: translateX(0) translateY(-3rem) translateZ(0);
      }

      @include breakpoint(md) {
        transform: translateX(0) translateZ(0);

        &:hover {
          transform: translateY(-2rem) translateZ(0);
        }
      }
    }

    &:nth-child(2) {
      transform: translateX(20px) translateY(5px) translateZ(20px);

      &:hover {
        transform: translateX(20px) translateY(-3rem) translateZ(20px);
      }

      @include breakpoint(md) {
        transform: translateY(5px) translateZ(20px);

        &:hover {
          transform: translateX(0) translateY(-2rem) translateZ(0);
        }
      }
    }

    &:nth-child(3) {
      transform: translateX(40px) translateY(10px) translateZ(40px);

      &:hover {
        transform: translateX(40px) translateY(-3rem) translateZ(40px);
      }

      @include breakpoint(md) {
        transform: translateX(0px) translateY(15px) translateZ(60px);

        &:hover {
          transform: translateX(0px) translateY(-2rem) translateZ(60px);
        }
      }
    }
  }
}

.analysis-card {
  width: 400px;
  height: 260px;
  max-width: 100%;
  border-radius: var(--radius-lg);
  background: var(--secondary-bg-color);
  @include shadow-border;
  transition: var(--time-slow) var(--curve-fast);

  @include breakpoint(md) {
    width: 100%;
  }

  &:hover {
    transition: var(--time-slow) var(--curve-fast);
  }

  h3 {
    font-size: 1.25rem;
    padding: var(--gutter) var(--gutter-md);
    border-bottom: 1px solid var(--border-color);
    margin: 0;
  }

  p {
    font-size: 0.9rem;
    padding: var(--gutter-md);
    margin: 0;
    opacity: 0.75;
  }

  svg {
    fill: var(--primary-color);
  }
}

.analysis-track {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gutter-md);
  aspect-ratio: 1 / 1;
  border-radius: var(--radius);
  background: rgba(var(--primary-color-rgb), 0.1);
  border: 1px solid rgba(var(--primary-color-rgb), 0.3);
  transform: translateZ(0);
  transition: var(--time) var(--curve-fast);

  @include breakpoint(md) {
    padding: var(--gutter);
  }

  &:hover {
    transform: translateZ(3rem);
    transition: var(--time) var(--curve-fast);
  }

  span {
    font-size: 2.5rem;
    color: var(--primary-color);
  }
}

.analysis-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: block;
  height: 2px;
  background: rgba(var(--primary-text-color-rgb), 0.1);
  z-index: -1;

  @include breakpoint(md) {
    transform: rotate(90deg);
  }
}
