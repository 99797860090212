@use "globals" as *;

.feedback-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--gutter);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    padding: 0.75rem;
    aspect-ratio: 1 / 1;
    margin-right: var(--gutter);
    border-radius: var(--radius-sm);
  }

  .emoji {
    font-size: 2rem;
    margin-right: var(--gutter);

    @include breakpoint(md) {
      font-size: 1.5rem;
    }
  }

  h6 {
    font-size: 1.2rem;
    line-height: 1;
    margin: 0 0 var(--gutter-sm) 0;

    @include breakpoint(md) {
      font-size: 1rem;
    }
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    opacity: 0.9;
  }
}
