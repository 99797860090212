@use "sass:map";

// shadow
@mixin shadow-soft() {
  box-shadow: 0 2px 6px var(--shadow-color);
}

@mixin shadow-border {
  box-shadow: 0 0 0 1px rgba(var(--primary-text-color-rgb), 0.1);
}

/* Color */
.bg-primary {
  background: var(--primary-bg-color) !important;
}

.bg-secondary {
  background: var(--secondary-bg-color) !important;
}

.bg-border {
  background: var(--border-color) !important;
}

.bg-primary-brand {
  background: var(--primary-color) !important;
}

.bg-primary-color\/10 {
  background: rgba(var(--primary-color-rgb), 0.1) !important;
}

.bg-secondary-brand {
  background: var(--secondary-color) !important;
}

.bg-secondary-brand\/10 {
  background: rgba(var(--secondary-color-rgb), 0.1) !important;
}

.bg-gold {
  background: #cfae54;
}

.bg-gold-light {
  background: rgba(var(--gold-color-rgb), 0.1);
}

.bg-muted {
  background: var(--border-color);
}

.bg-red {
  background: var(--red-color);
}

.bg-red-light {
  background: rgba(var(--red-color-rgb), 0.1);
}

.bg-orange {
  background: var(--orange-color);
}

.bg-orange-light {
  background: rgba(var(--orange-color-rgb), 0.1);
}

.bg-green {
  background: var(--green-color);
}

.bg-green-light {
  background: rgba(var(--green-color-rgb), 0.1);
}

.bg-blue {
  background: var(--blue-color);
}

.bg-blue-light {
  background: rgba(var(--blue-color-rgb), 0.1);
}

.bg-purple {
  background: var(--purple-color);
}

.bg-purple-light {
  background: rgba(var(--purple-color-rgb), 0.1);
}

.bg-popover {
  background: var(--secondary-bg-color) !important;
}

.bg-radial {
  background: radial-gradient(
    circle,
    rgba(var(--primary-text-color-rgb), 0.05),
    rgba(var(--primary-text-color-rgb), 0.01)
  );
}

.bg-muted {
  background: var(--border-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-foreground {
  color: var(--primary-text-color) !important;
}

.text-theme-primary {
  color: var(--theme-primary-color) !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: rgba(var(--primary-color-rgb), 0.8) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-tertiary {
  color: var(--tertiary-color) !important;
}

.text-gold {
  color: var(--gold-color) !important;
}

.text-red {
  color: var(--red-color) !important;
}

.text-orange {
  color: var(--orange-color) !important;
}

.text-green {
  color: var(--green-color) !important;
}

.text-blue {
  color: var(--blue-color) !important;
}

.text-purple {
  color: var(--purple-color) !important;
}

.text-gradient {
  color: transparent;
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
}

.text-tiktok {
  color: var(--tiktok-primary-color) !important;
}

.text-youtube {
  color: var(--youtube-primary-color) !important;
}

.text-spotify {
  color: var(--spotify-primary-color) !important;
}

/* Border Colors */
.border-primary-brand {
  border-color: var(--primary-color) !important;
}

.border-primary-brand\/20 {
  border-color: rgba(var(--primary-color-rgb), 0.2) !important;
}

.border-secondary-brand {
  border-color: var(--secondary-color) !important;
}

.border-secondary-brand\/20 {
  border-color: rgba(var(--secondary-color-rgb), 0.2) !important;
}

.strike-through {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: currentColor;
    transform: rotate(-20deg);
  }
}

.hoverable {
  transition: background-color var(--time-faster) var(--curve-fast);

  &:hover {
    background-color: rgba(var(--primary-text-color-rgb), 0.1);
    transition: background-color var(--time-faster) var(--curve-fast);
  }
}

.border-outline {
  box-shadow: 0 0 0 1px var(--border-color);
}

.text-gradient-primary {
  color: transparent;
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
}

.text-gradient-secondary {
  color: transparent;
  background: linear-gradient(90deg, var(--secondary-color), var(--tertiary--color));
  -webkit-background-clip: text;
}

.text-gradient-primary-fade {
  color: transparent;
  background: linear-gradient(0deg, rgba(var(--primary-color-rgb), 0.6), rgba(var(--primary-color-rgb), 1));
  -webkit-background-clip: text;
}

.text-gradient-secondary-fade {
  color: transparent;
  background: linear-gradient(0deg, rgba(var(--secondary-color-rgb), 0.6), rgba(var(--secondary-color-rgb), 1));
  -webkit-background-clip: text;
}

.text-gradient-tertiary-fade {
  color: transparent;
  background: linear-gradient(0deg, var(--tertiary-color), rgba(var(--tertiary-color-rgb), 0.6));
  -webkit-background-clip: text;
}

.text-gradient-gold-fade {
  color: transparent;
  background: linear-gradient(0deg, rgba(var(--gold-color-rgb), 0.6), rgba(var(--gold-color-rgb), 1));
  -webkit-background-clip: text;
}

.text-gradient-fade {
  color: transparent;
  background: linear-gradient(0deg, rgba(var(--primary-text-color-rgb), 0.6), rgba(var(--primary-text-color-rgb), 1));
  -webkit-background-clip: text;
}

.text-gradient-light-blue-fade {
  color: transparent;
  background: linear-gradient(0deg, rgba(var(--blue-color-rgb), 0.6), rgba(var(--blue-color-rgb), 1));
  -webkit-background-clip: text;
}

.highlight {
  &-yellow {
    background-color: rgba(var(--yellow-color-rgb), 0.1);
    color: var(--yellow-color);
  }
}

/* 3D Skew */
.iso-right {
  transform: translateX(0%) rotateX(45deg) rotateZ(45deg);
}

.iso-left {
  transform: translateX(0%) rotateX(45deg) rotateZ(-45deg);
}
