@use "globals" as *;

.nav-mobile {
  display: flex;
  align-items: center;
  gap: var(--gutter-sm);
  padding: var(--gutter) var(--gutter-sm);
  overflow-x: scroll;
  border-bottom: 1px solid var(--border-color);

  ::-webkit-scrollbar {
    display: none;
  }

  li {
    a {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: 0.8rem;
      @include shadow-border;
      padding: var(--gutter-sm) var(--gutter);
      border-radius: var(--radius-full);

      &.active {
        background-color: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
        font-weight: 500;
      }

      i,
      svg {
        margin-right: var(--gutter-xs);
      }
    }
  }
}
