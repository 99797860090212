@use "globals" as *;

.avatar {
  position: relative;
  width: var(--avatar-size);
  min-width: var(--avatar-size);
  height: var(--avatar-size);
  min-height: var(--avatar-size);
  overflow: hidden;
  border-radius: 50%;
  margin: 0;
  --avatar-size: 54px;

  &-name {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #909090;
    color: #f9f9f9;
    font-weight: 500;
    font-size: 1.2rem;
  }

  &-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--primary-text-color-rgb), 0.1);
    color: rgba(var(--primary-text-color-rgb), 0.8);

    .icon svg {
      color: rgba(var(--primary-text-color-rgb), 0.5);
    }
  }

  &.xs {
    --avatar-size: 24px;
  }

  &.sm {
    --avatar-size: 32px;

    &.avatar-star {
      --avatar-gap: 0.2rem;

      .verified {
        right: -0.2rem;
        bottom: -0.1rem;

        svg {
          width: 14px;
        }
      }
    }
  }

  &.md {
    --avatar-size: 64px;
  }

  &.lg {
    --avatar-size: 80px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.hoverable {
    &:after {
      content: "";
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      &:after {
        display: block;
        opacity: 1;
      }
    }
  }

  .placeholder {
    border: 0;
    padding: 0;

    &.sm {
      --placeholder-radius: 50%;

      .icon {
        width: 1rem;
        height: 1rem;
      }
    }

    &.md {
      --placeholder-radius: 50%;

      .icon {
        width: 2rem;
        height: 2rem;
      }
    }

    &.lg {
      --placeholder-radius: 50%;

      .icon {
        width: 3rem;
        height: 3rem;
      }
    }

    &.xl {
      --placeholder-radius: 50%;

      .icon {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
