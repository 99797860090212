@use "globals" as *;

.credit-score {
  display: inline-flex;
  align-items: center;
  gap: var(--gutter-xs);
}

.coin-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: radial-gradient(rgba(var(--yellow-color-rgb), 1) 70%, rgba(var(--gold-color-rgb), 1));
  border: 1px solid rgba(var(--dark-color-rgb), 0.1);
  border-bottom: 2px dashed rgba(var(--dark-color-rgb), 0.1);
  color: rgba(var(--dark-color-rgb), 0.3);
  font-size: 10px;
  border-radius: 50%;
  transition: transform var(--time) ease;

  &:hover {
    transform: rotateY(360deg);
  }
}
