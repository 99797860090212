@use "globals" as *;

.card-promotion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--secondary-bg-color);
  border-radius: var(--radius);
  overflow: hidden;
  padding: 1rem;
  border: 1px dashed rgba(var(--primary-text-color-rgb), 0.2);
}
