.iubenda-tp-alert-btn:not([data-tp-nostyle]),
.iubenda-tp-btn:not([data-tp-nostyle]),
.iubenda-uspr-btn:not([data-tp-nostyle]),
.iubenda-tp-btn-container,
.iubenda-tp-btn,
.iubenda-tp-alert-btn[data-tp-float][data-tp-float="bottom-right"],
.iubenda-tp-alert-btn[data-tp-float][data-tp-float="top-right"],
.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-right"],
.iubenda-tp-btn[data-tp-float][data-tp-float="top-right"],
.iubenda-uspr-btn[data-tp-float][data-tp-float="bottom-right"],
.iubenda-uspr-btn[data-tp-float][data-tp-float="top-right"],
a.iubenda-tp-btn.iubenda-cs-preferences-link {
  display: none !important;
}
