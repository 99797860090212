@use "globals" as *;

.payment-dialog {
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
}

.payment-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--gutter);
  gap: var(--gutter-sm);
  border-radius: var(--radius);
  @include shadow-border;

  @include breakpoint(sm) {
    grid-column: span 2;
    height: auto;
  }

  label {
    font-size: 1.15rem;
  }

  h4 {
    font-size: 1.5rem;
    margin: 0;
  }
}

.payment-discount {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  border-radius: 0 var(--radius) 0 var(--radius);
}

.payment-details {
  position: relative;
  background-color: var(--secondary-bg-color);
  border-radius: var(--radius);
  border: 1px dashed rgba(var(--primary-text-color-rgb), 0.3);

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem var(--gutter);
    border-bottom: 1px dashed rgba(var(--primary-text-color-rgb), 0.3);

    label {
      font-size: 1rem;
      margin: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.payment-item {
  width: 100%;
  padding: var(--gutter);
  border-radius: var(--radius);
  border: 1px solid var(--border-color);

  h4 {
    font-size: 1.2rem;

    @include breakpoint(sm) {
      font-size: 1rem;
    }
  }

  [class*="icon-"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--icon-size);
    height: var(--icon-size);
    flex-shrink: 0;
    padding: var(--gutter);
    border-radius: var(--radius-sm);
    border-width: 1px;
    --icon-size: 54px;

    @include breakpoint(sm) {
      --icon-size: 48px;
    }

    i {
      font-size: 1.5rem;

      @include breakpoint(sm) {
        font-size: 1.2rem;
      }
    }
  }
}

.payment-total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(var(--green-color-rgb), 0.1);
  padding: 0.75rem var(--gutter);
  border-radius: var(--radius);
  border: 1px dashed rgba(var(--green-color-rgb), 0.3);

  h4 {
    font-size: 1.4rem;
    margin: 0;
  }
}

.modal-content {
  .StripeElement,
  #before-pay {
    padding: 0 var(--gutter);
  }

  .payment-calculator {
    padding: var(--gutter);
  }
}

.payment-secure {
  border-top: 1px solid var(--border-color);
  padding-top: var(--gutter);

  &-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gutter-xs);

    span {
      font-weight: 600;
    }

    i {
      font-size: 1rem;
    }

    label {
      font-size: 1rem;
      margin: 0;
    }
  }

  &-logos {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.15rem;

    .payment-logo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      border: 1px solid var(--border-color);
      border-radius: var(--radius-sm);

      svg {
        width: 70%;
        height: 32px;
        object-position: 50% 50%;
        object-fit: contain;
      }
    }
  }
}

.payment-cta {
  position: sticky;
  background-color: var(--primary-bg-color);
  left: 0;
  right: 0;
  bottom: 0;
}
