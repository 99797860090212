@use "globals" as *;

.device-mockup {
  position: relative;
  margin: 0 auto;
  border-color: var(--mockup-color);
  background-color: var(--mockup-color);
  border-width: 0.8rem;
  border-radius: 1.8rem;
  width: 100%;
  --mockup-color: var(--border-color);

  .pin {
    position: absolute;
    background-color: var(--mockup-color);
    width: 4px;
    height: 32px;

    &.pin-left-1 {
      top: 72px;
      left: -16px;
      border-radius: 0.375rem 0 0 0.375rem;
    }

    &.pin-left-2 {
      top: 124px;
      left: -16px;
      border-radius: 0.375rem 0 0 0.375rem;
    }

    &.pin-right {
      top: 142px;
      height: 64px;
      right: -16px;
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }

  &-inner {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    border: 1px solid var(--border-color);
    background-color: var(--secondary-bg-color);
  }
}
