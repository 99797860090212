@use "globals" as *;

:root {
  --input-font-size: 1.1rem;
  --flag-dropdown-width: 50px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="month"],
input[type="tel"],
input[type="email"],
input[type="url"] {
  font-size: 1rem;
  text-align: left;
  background-color: var(--input-color);
  color: var(--primary-text-color);
  width: 100%;
  border-radius: var(--radius);
  padding: var(--gutter-xs) var(--gutter);
  height: var(--input-height);
  min-height: var(--input-height);
  outline: none;
  transition: var(--time) ease-in-out;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background-color: rgba(var(--input-color-rgb), 1);
  }

  &:disabled,
  &:read-only {
    background-color: var(--input-color);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    color: var(--primary-text-color);
    outline: none;
    box-shadow: none;
  }
}

textarea {
  font-size: 1rem;
  text-align: left;
  background-color: var(--input-color);
  color: var(--primary-text-color);
  width: 100%;
  border-radius: var(--radius);
  padding: var(--gutter);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background-color: rgba(var(--input-color-rgb), 1);
  }

  &:disabled,
  &:read-only {
    background-color: var(--input-color);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    color: var(--primary-text-color);
    outline: none;
    box-shadow: none;
  }
}

fieldset {
  &:disabled {
    cursor: not-allowed;
  }
}
