@use "globals" as *;

.popover {
  &-content {
    background-color: var(--secondary-bg-color);
    padding: 0 !important;
    border-color: var(--border-color);

    .command-input {
      border-color: var(--border-color);
    }
  }
}
