@use "globals" as *;

.section-header {
  background-color: var(--primary-bg-color);
  padding-bottom: var(--gutter);
  border-bottom: 1px solid var(--border-color);
  transition: var(--time-fast) all var(--curve-fast);

  &.is-sticky {
    padding-top: var(--gutter);
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gutter);

    h3 {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0;
      opacity: 0.9;
    }
  }

  &-actions {
    display: flex;
    gap: var(--gutter-sm);
  }

  &.sticky {
    position: sticky;
    top: var(--navbar-height);
    z-index: 20;

    @include breakpoint(md) {
      top: var(--navbar-height-mobile);
    }
  }

  &.with-actions {
    .section-header-actions {
      .button {
        min-width: 150px;

        @include breakpoint(md) {
          min-width: unset;
        }
      }
    }

    @include breakpoint(md) {
      .section-header-content {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--gutter-sm);

        h3 {
          display: none;
        }
      }

      .section-header-actions {
        display: flex;
        width: 100%;

        .button {
          flex: 1;
        }
      }
    }
  }
}

.modal-content {
  .section-header.sticky {
    top: 0;
    border-bottom: none;
  }
}
