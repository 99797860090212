@use "globals" as *;

.vip-campaign {
  .input-card {
    @include breakpoint(sm) {
      width: 100%;
    }
  }

  &-help {
    display: flex;
    align-items: center;
    gap: var(--gutter-sm);
    z-index: 100;

    @media screen and (min-width: $breakpoint-md) {
      position: absolute;
      top: var(--gutter-md);
      right: var(--gutter-md);
      justify-content: flex-end;
    }

    @include breakpoint(sm) {
      justify-content: space-between;
      margin-bottom: var(--gutter);
    }

    p {
      font-size: 0.85rem;
      line-height: 1.4;
      opacity: 0.7;
      max-width: 18ch;

      @include breakpoint(sm) {
        max-width: 100%;
      }
    }
  }

  &-summary {
    h3 {
      font-size: 1.5rem;
      margin-bottom: var(--gutter);
    }

    .info-value {
      @media screen and (min-width: $breakpoint-md) {
        text-align: right;
      }
    }
  }

  .map-card {
    width: 150px;

    @include breakpoint(sm) {
      width: 100%;
    }

    .input-card-image {
      height: 90px;

      @include breakpoint(sm) {
        display: none;
      }
    }
  }
}

.vip-countries {
  .input-card-label {
    line-height: 1.25;
    white-space: normal;
  }
}
