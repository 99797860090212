@use "globals" as *;

.filters {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  gap: var(--gutter-sm);

  @include breakpoint(md) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin-top: var(--gutter-sm);
  }
}

.filter-item {
  &.filter-sort {
    flex: 1;

    .select {
      width: 100%;
    }
  }

  @include breakpoint(md) {
    width: 100%;

    > * {
      width: 100%;
    }

    .button-group .button {
      flex: 1;
      text-align: center;
    }
  }

  h6 {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    opacity: 0.75;
  }
}
