@use "globals" as *;

.intercom-lightweight-app-launcher {
  background: var(--primary-color) !important;
  bottom: 6rem !important;
}

.intercom-launcher-frame,
.intercom-1ffmy2o {
  background: var(--primary-color) !important;

  button {
    background: var(--primary-color) !important;
  }
}

$launcher-color: var(--primary-color);
$launcher-size: 50px;
$launcher-count-size: 20px;
$launcher-count-color: #fc576b;

.intercom-launcher {
  backface-visibility: hidden;
  background: $launcher-color;

  &.intercom-booted {
    height: $launcher-size;
    opacity: 1;
    transform: scale(1);
    width: $launcher-size;
  }

  * {
    cursor: pointer;
  }
}

.intercom-unread-count {
  backface-visibility: hidden;
  background: $launcher-count-color;
  border-radius: #{calc($launcher-count-size / 2)};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: #fff;
  font-size: $launcher-count-size * 0.6;
  height: $launcher-count-size;
  line-height: $launcher-count-size;
  min-width: $launcher-count-size;
}
