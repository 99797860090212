@use "globals" as *;

.search-select {
  &-option {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: var(--radius-xs);
      margin-right: var(--gutter-sm);
    }
  }
}

.search-select-option-artist {
  img {
    border-radius: 50%;
  }
}
