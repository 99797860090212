@use "globals" as *;

.input-card {
  position: relative;
  display: inline-block;
  background-color: var(--secondary-bg-color);
  border-radius: var(--radius);
  cursor: pointer;
  border: 1px solid var(--border-color);
  opacity: 0.85;
  margin: 0;
  transition: var(--time) all ease-in-out;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @include breakpoint(sm) {
    width: auto;
    height: auto;
  }

  &:hover {
    background: rgba(var(--primary-text-color-rgb), 0.1);
  }

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }

  &.active {
    background: var(--primary-text-color);
    color: var(--primary-bg-color);
    opacity: 1;
  }

  &.error {
    border-color: var(--red-color);
  }

  > svg {
    width: 48px;
    height: 48px;
    margin-bottom: auto;

    @include breakpoint(sm) {
      width: 32px;
      height: 32px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gutter);
    text-align: left;
    height: 100%;
    padding: var(--gutter);

    .input-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &-label {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.15;
    max-width: 80%;

    @include breakpoint(sm) {
      font-size: 1rem;
    }
  }

  &-subtitle {
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1.15;
    opacity: 0.75;
  }

  &-image {
    position: relative;
    width: 100%;
    height: 150px;

    img,
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
