@use "globals" as *;

.not-found {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  h1 {
    font-size: clamp(4rem, 5vw, 8rem);
    margin-top: -8%;
    margin-bottom: var(--gutter);

    @include breakpoint(sm) {
      font-size: 3rem;
    }
  }

  p {
    font-size: clamp(1.5rem, 1.5vw, 3rem);
    font-weight: 300;
    max-width: 32ch;
    text-wrap: balance;
  }

  svg {
    max-width: $breakpoint-xs;
    opacity: 0.1;

    path {
      fill: var(--primary-text-color);
    }
  }
}
