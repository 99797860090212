@use "globals" as *;

.playlist-cell {
  position: relative;
  padding: var(--gutter-xs);
  background-color: rgba(var(--primary-text-color-rgb), 0.1);
  aspect-ratio: 1 / 1;
  border-radius: var(--radius);
  width: var(--playlist-cover);
  height: var(--playlist-cover);
  overflow: hidden;
  grid-column: span 1;
  @include shadow-border;
  @include shadow-soft;
  --playlist-cover: 120px;
  transition: var(--time) all var(--curve-fast);

  @include breakpoint(sm) {
    padding: 0;
    border-radius: calc(var(--radius) - #{var(--gutter-xs)});
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
    object-fit: cover;
  }
}
