@use "globals" as *;

.image {
  position: relative;
  width: var(--image-size);
  min-width: var(--image-size);
  height: var(--image-size);
  min-height: var(--image-size);
  overflow: hidden;
  border-radius: var(--image-radius);
  margin: 0;
  --image-size: 96px;
  --image-radius: 1rem;

  &.xs {
    --image-size: 32px;
    --image-radius: 0.2rem;
  }

  &.sm {
    --image-size: 48px;
    --image-radius: 0.4rem;
  }

  &.md {
    --image-size: 64px;
    --image-radius: 0.5rem;
  }

  &.lg {
    --image-size: 80px;
    --image-radius: 1rem;
  }

  &.xl {
    --image-size: 128px;
    --image-radius: 1.5rem;
  }
}
