@use "globals" as *;

.spinner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gutter-sm);
  --spinner-size: 5rem;
  --spinner-logo-size: 2.5rem;

  .spinner {
    position: relative;
    width: var(--spinner-size);
    height: var(--spinner-size);
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: var(--spinner-size);
      height: var(--spinner-size);
      background-color: var(--secondary-bg-color);
      border: 2px solid var(--border-color);
      border-top: 2px solid var(--primary-color);
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    .spinner-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: var(--spinner-logo-size);
      height: var(--spinner-logo-size);
    }
  }

  .spinner-inline {
    border: 2px solid transparent;
    border-top-color: currentColor;
    background-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    --spinner-size: 1.25rem;
    --spinner-logo-size: 0.5rem;

    &::before {
      display: none;
    }
  }
}

.spinner-message {
  font-weight: 500;
}

.spinner-audio {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  #wave {
    height: 32px;
    width: 32px;
    fill: var(--primary-color);
  }

  @for $i from 1 through 9 {
    #line-#{$i} {
      animation: pulse 0.5s infinite;
      animation-delay: $i * 0.15s;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }

  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}
