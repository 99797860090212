@use "globals" as *;

.checkbox,
input[type="checkbox"] {
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--theme-primary-color);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  -webkit-appearance: none;
  appearance: none;

  &:before {
    content: "";
    width: 0.6em;
    height: 0.6em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--theme-primary-color);
    background-color: CanvasText;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[data-state="checked"],
  &:checked {
    background-color: rgba(var(--theme-primary-color-rgb), 0.3);
    color: rgba(var(--theme-primary-color-rgb), 1);
    border-color: var(--theme-primary-color);

    &:before {
      transform: scale(1);
    }
  }
}

label {
  input[type="checkbox"] {
    margin-right: var(--gutter-sm);
  }
}
