@use "globals" as *;

.pagination {
  display: flex;
  align-items: center;
  margin-top: var(--gutter);
  border-radius: var(--radius);
  overflow: hidden;
  justify-content: center;

  li {
    border: 1px solid rgba(var(--primary-text-color-rgb), 0.85);
    border-right: 0;

    &:first-child {
      border-radius: var(--radius) 0 0 var(--radius);
      border-left-width: 1px;
    }

    &:last-child {
      border-radius: 0 var(--radius) var(--radius) 0;
      border-right: 1px solid;
    }

    a {
      display: block;
      color: rgba(var(--primary-text-color-rgb), 0.85);
      padding: var(--gutter-sm) var(--gutter);

      &:hover {
        color: var(--primary-text-color);
      }
    }
  }

  .active {
    a {
      background: var(--primary-text-color);
      color: var(--primary-bg-color);
      font-weight: 500;

      &:hover {
        color: var(--primary-bg-color);
      }
    }
  }

  .disabled {
    opacity: 0.5;

    a {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
