@use "globals" as *;

.error-login {
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 80vh;
    padding-top: 2rem;
    padding-bottom: 12rem;

    @include breakpoint(sm) {
      padding-top: 4rem;
      padding-bottom: 8rem;
    }

    h1 {
      font-size: clamp(4rem, 8vw, 12rem);
      margin-top: -10%;
      margin-bottom: var(--gutter-lg);

      @include breakpoint(sm) {
        font-size: 3rem;
        margin-bottom: var(--gutter);
      }
    }

    p {
      font-size: clamp(1.5rem, 2vw, 3rem);
      font-weight: 300;
      max-width: 32ch;
      text-wrap: balance;
    }

    svg {
      max-width: $breakpoint-xs;
      opacity: 0.15;

      @include breakpoint(sm) {
        max-width: 80%;
      }

      path {
        fill: var(--primary-text-color);
      }
    }
  }
}
