@use "globals" as *;

.curator {
  &-actions {
    display: flex;
    align-items: center;
    gap: var(--gutter-sm);

    @include breakpoint(xs) {
      flex-direction: column;
      align-items: center;
      width: 100%;

      > *,
      button,
      .modal-trigger {
        flex: 1;
        width: 100%;
      }
    }
  }

  table {
    .playlist-cover {
      border-radius: var(--radius-sm);
      --playlist-cover-size: 64px;

      @include breakpoint(sm) {
        --playlist-cover-size: 48px;
      }
    }

    .placeholder i,
    .placeholder svg {
      font-size: 1.5rem;
    }
  }
}

.curator-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--gutter-md) var(--gutter);
  border: 1px solid transparent;
  transition: var(--time-slow) all var(--curve-fast);
  --image-size: 120px;

  @include breakpoint(md) {
    --image-size: 100px;
  }

  @include breakpoint(sm) {
    --image-size: 80px;
  }

  &:hover {
    border-color: rgba(var(--primary-text-color-rgb), 0.15);
    opacity: 0.9;
    transform: scale(0.97);
    transition: var(--time-slow) all var(--curve-fast);
  }

  .avatar {
    --avatar-size: var(--image-size);
    margin: 0 auto var(--gutter) auto;
  }

  .placeholder {
    width: var(--image-size);
    height: var(--image-size);
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    word-break: break-word;

    @include breakpoint(sm) {
      font-size: 1rem;
    }
  }
}

.curator-card-featured {
  background-color: rgba(var(--primary-color-rgb), 0.1);
  border: 1px solid rgba(var(--primary-color-rgb), 0.2);

  &:hover {
    border-color: rgba(var(--primary-color-rgb), 0.3);
  }
}

.curator-detail {
  padding-top: 3rem;

  .avatar {
    --avatar-size: 150px;
  }
}

.curator-playlist {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius);
  padding: var(--gutter);
  gap: var(--gutter);

  @include breakpoint(sm) {
    align-items: center;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    text-wrap: balance;
    margin: 0;
  }

  .playlist-cover {
    border-radius: var(--radius-sm);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.curator-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--gutter);
  gap: var(--gutter);
  @include shadow-border;
  transition: var(--time-slow) all var(--curve-fast);

  &:hover {
    opacity: 0.8;
    transform: scale(0.99);
    transition: var(--time-slow) all var(--curve-fast);
  }

  &-featured {
    background-color: rgba(var(--primary-color-rgb), 0.1);
    border: 1px solid rgba(var(--primary-color-rgb), 0.2);

    &:hover {
      border-color: rgba(var(--primary-color-rgb), 0.3);
    }
  }

  .avatar {
    margin: 0;
    --avatar-size: 54px;
  }

  h3 {
    font-size: 1.25rem;
    word-break: break-word;
    margin-bottom: var(--gutter-sm);

    @include breakpoint(sm) {
      font-size: 1rem;
    }
  }
}

.curator-score {
  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

  &-data {
    display: flex;
    align-items: center;
    gap: var(--gutter-sm);

    span {
      display: flex;
      align-items: center;
      gap: var(--gutter-xs);
    }
  }
}

.curator-score-full {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gutter);
  @include shadow-border;
  padding: var(--gutter);
  border-radius: var(--radius);

  h4 {
    font-size: 1.5rem;
    font-weight: 600;
    opacity: 1;
  }

  .curator-score-data {
    flex-wrap: wrap;
  }
}

.table-playlist {
  .curator-score {
    font-size: 0.85rem;
    gap: var(--gutter-sm);

    h4 {
      font-size: 0.9rem;
      margin: 0 0 var(--gutter-xs) 0;
      white-space: nowrap;
    }
  }

  .playlist-cover {
    --playlist-cover-size: 64px;
  }
}
