@use "globals" as *;

.maintenance {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  min-height: 100svh;
  padding-top: 10rem;
  padding-bottom: 10rem;

  @include breakpoint(sm) {
    padding-top: 8rem;
    padding-bottom: 5rem;
  }

  &-logo {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 200px;
    margin: 0 auto;
  }

  .section-title {
    @include breakpoint(sm) {
      h1 {
        font-size: 2rem;
      }

      span {
        font-size: 1.25rem;
      }
    }
  }

  &-footer {
    margin-top: 4rem;

    @media screen and (min-width: $breakpoint-md) {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
