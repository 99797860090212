@use "globals" as *;

.match-header {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: calc(var(--navbar-height) + var(--gutter-lg));
  background-color: rgba(var(--secondary-bg-color-rgb), 0.95);
  padding: var(--gutter-sm) var(--gutter-sm) var(--gutter) var(--gutter);
  margin-top: var(--gutter-lg);
  border-radius: var(--radius);
  gap: var(--gutter-sm);
  @include shadow-border;
  margin-bottom: var(--gutter);
  backdrop-filter: blur(4px);
  z-index: 20;

  @include breakpoint(md) {
    position: relative;
    top: 0;
    gap: var(--gutter-sm);
    padding: var(--gutter);
    margin-top: var(--gutter);
  }

  .tab-switch {
    padding: 0;

    .tab-header {
      flex-wrap: nowrap;
    }
  }
}

.match-form {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "artist artist cover"
    "step1 step1 cover"
    "step2 step2 cover"
    "alert alert alert"
    "submit submit submit";
  gap: var(--gutter) var(--gutter-md);

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "artist artist"
      "step1 step1"
      "step2 step2"
      "alert alert"
      "submit submit";
  }

  &.compact {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "artist artist"
      "step1 step2"
      "alert alert"
      "submit submit";

    @include breakpoint(md) {
      grid-template-areas:
        "artist artist"
        "step1 step1"
        "step2 step2"
        "alert alert"
        "submit submit";
    }
  }

  &.agency {
    grid-template-rows: 0 auto;
  }
}

.match-widget {
  background-color: var(--secondary-bg-color);
  padding: var(--gutter);
  border-radius: var(--radius-lg);
  @include shadow-border;
}

.match-message {
  position: relative;
  font-weight: 500;
  border: 1px solid rgba(var(--theme-primary-color-rgb), 0.3);
  padding: var(--gutter);
  gap: var(--gutter);
  border-radius: var(--radius-sm);
  background-color: rgba(var(--theme-primary-color-rgb), 0.1);

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-body {
    > *:not(:last-child) {
      margin-bottom: var(--gutter);
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    a {
      color: var(--theme-primary-color);
      font-weight: 600;
      text-decoration: underline;
      word-break: break-all;
      white-space: wrap;
    }
  }

  .select__control {
    background-color: rgba(var(--theme-primary-color-rgb), 0.1) !important;
    min-width: 150px;
  }
}
