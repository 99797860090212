@use "globals" as *;

.rocket {
  max-width: 500px;
  border-radius: 50%;
  background: linear-gradient(to top, var(--overlay-color) 40%, var(--theme-tertiary-color));

  @include breakpoint(sm) {
    max-width: 100%;
  }

  svg {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}

/* Keyframes */
@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes signal {
  33% {
    fill: var(--primary-color);
  }
  66% {
    fill: var(--secondary-color);
  }
}

@keyframes comet {
  0% {
    transform: rotate(10deg) translate3d(300px, -300px, 0);
    opacity: 0;
  }

  25% {
    opacity: 0.9;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    transform: rotate(0deg) translate3d(-400px, 400px, 0px);
    opacity: 0.25;
  }
}

@keyframes launch {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -1000px, 0px);
  }
}

@keyframes smoke {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(1.5, 1, 1);
  }
}

@keyframes flame {
  50% {
    opacity: 0.75;
  }
}

#smoke {
  opacity: 0;
  transform-origin: 50% 0%;
}

#rocket .flame {
  animation: flame 2s 0s ease-in-out infinite both;
}

.stars {
  animation: blink 8s -1s ease-in-out infinite both;
}

[class^="dot"] {
  animation: blink 5s -3s ease-in-out infinite both;
}

.comet {
  transform-origin: 100% 50%;
  &.one {
    animation: comet 5s 0s ease-in-out infinite both;
  }
  &.two {
    animation: comet 5s -2s ease-in-out infinite both;
  }
}

/* Launch */
svg.launched {
  #signal {
    animation: signal 5s -1s ease-in-out infinite both;
  }
  #smoke {
    animation: smoke 7s 0s ease-out normal forwards;
  }
  #rocket {
    animation: launch 5s 2s ease-in normal forwards;
  }
}
