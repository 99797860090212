@use "globals" as *;

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--gutter-sm);
  border: 1px solid transparent;
  padding: var(--gutter-sm) var(--gutter-md);
  border-radius: var(--radius);
  font-weight: 600;
  outline: none;
  cursor: pointer;
  transition: 0.35s all;

  @include breakpoint(sm) {
    padding: var(--gutter-sm) var(--gutter);
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    transition: 0.35s all;
  }

  &-icon {
    padding: 0.75rem 1rem;

    i,
    svg {
      margin: 0;
    }
  }

  &-label {
    display: flex;
    align-items: center;
    gap: var(--gutter-xs);
  }

  &-extra-small {
    padding: var(--gutter-xs) var(--gutter-sm);
    font-size: 0.8rem;
    border-radius: var(--radius-sm);
  }

  &-small {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  &-large {
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
  }

  &-wide {
    width: 100%;
    text-align: center;
  }

  &-primary {
    background: var(--primary-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--primary-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--white-color);
    }
  }

  &-light {
    background: rgba(var(--primary-text-color-rgb), 0.1);
    color: var(--primary-text-color);

    &:hover {
      background: var(--primary-text-color);
      color: var(--primary-bg-color);
    }
  }

  &-primary-light {
    background: rgba(var(--primary-color-rgb), 0.1);
    color: var(--primary-color);

    &:hover {
      background: rgba(var(--primary-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--primary-color);
    }
  }

  &-theme-primary {
    background: var(--theme-primary-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--theme-primary-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--white-color);
    }
  }

  &-theme-primary-light {
    background: rgba(var(--theme-primary-color-rgb), 0.1);
    color: var(--theme-primary-color);

    &:hover {
      background: rgba(var(--theme-primary-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--theme-primary-color);
    }
  }

  &-secondary {
    background: var(--secondary-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--secondary-color-rgb), 0.8);
      color: var(--white-color);
    }
  }

  &-tertiary {
    background: var(--tertiary-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--tertiary-color-rgb), 0.8);
      color: var(--white-color);
    }
  }

  &-inverse {
    background: var(--primary-text-color);
    color: var(--primary-bg-color);

    &:hover {
      background: rgba(var(--primary-text-color-rgb), 0.6);
      color: var(--primary-bg-color);

      i,
      svg {
        color: var(--primary-bg-color);
      }
    }
  }

  &-inverse-light {
    background: rgba(var(--primary-text-color-rgb), 0.05);
    color: var(--inverse-color);

    &:hover {
      background: rgba(var(--primary-text-color-rgb), 0.9);
      color: var(--primary-bg-color);
    }

    .loading {
      --spinner-color: var(--primary-text-color);
    }
  }

  &-ghost {
    background-color: transparent;
    color: var(--primary-text-color);

    &:hover {
      background-color: rgba(var(--primary-text-color-rgb), 0.1);
      color: var(--primary-text-color);
    }
  }

  &-link {
    color: var(--primary-color);
    background-color: transparent;
    text-decoration: underline;
    padding: 0;
  }

  &-link-plain {
    color: currentColor;
    background-color: transparent;
    gap: var(--gutter-xs);
    padding: 0;
  }

  &-cta {
    font-size: 1.4rem;

    @include breakpoint(sm) {
      font-size: 1.15rem;
    }
  }

  &-gradient {
    background: linear-gradient(to right, var(--theme-primary-color) 0%, var(--theme-secondary-color) 100%);
    background-size: 200% auto;
    border: 0;
    transition: 0.35s all;
    color: var(--white-color);

    &:hover {
      background-position: right;
      transition: 0.35s all;
      color: var(--white-color);
    }
  }

  &-green {
    background: var(--green-color);
    border: 1px solid var(--green-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--green-color-rgb), 0.9);
      color: var(--white-color);
    }
  }

  &-green-light {
    background: rgba(var(--green-color-rgb), 0.1);
    color: var(--green-color);

    &:hover {
      background: rgba(var(--green-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--green-color);
    }
  }

  &-gold {
    background: var(--gold-color);
    border: 1px solid var(--gold-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--gold-color-rgb), 0.9);
      color: var(--white-color);
    }
  }

  &-outline-gold {
    background: transparent;
    color: var(--gold-color);
    border: 1px solid var(--gold-color);

    &:hover {
      background: var(--gold-color);
      color: var(--white-color);

      i,
      svg {
        color: var(--white-color);
      }
    }
  }

  &-disabled {
    background: var(--neutral-color);
    border: 1px solid var(--neutral-color);
    color: var(--white-color);
    cursor: not-allowed;

    &:hover {
      background: rgba(var(--neutral-color-rgb), 0.8);
      color: var(--white-color);
    }
  }

  &-outline {
    background: transparent;
    color: var(--primary-text-color);
    border: 1px solid var(--primary-text-color);

    &:hover {
      background: var(--primary-text-color);
      color: var(--primary-bg-color);

      i,
      svg {
        color: var(--primary-bg-color);
      }
    }
  }

  &-outline-primary {
    background: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);

    &:hover {
      background: var(--primary-color);
      color: var(--white-color);

      i,
      svg {
        color: var(--white-color);
      }
    }
  }

  &-orange {
    background: var(--orange-color);
    border: 1px solid var(--orange-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--orange-color-rgb), 0.9);
      color: var(--white-color);
    }
  }

  &-orange-light {
    background: rgba(var(--orange-color-rgb), 0.1);
    color: var(--orange-color);

    &:hover {
      background: rgba(var(--orange-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--orange-color);
    }
  }

  &-empty {
    background: transparent;
    color: var(--primary-text-color);
    border: 1px solid transparent;
    padding: 0;

    &:hover {
      background: transparent;
      color: var(--primary-text-color);
      border: 1px solid transparent;
    }
  }

  &-clear {
    background: transparent;
    color: rgba(var(--primary-text-color-rgb), 0.5);
    padding: var(--gutter-sm);

    &:hover {
      color: rgba(var(--primary-text-color-rgb), 0.9);
      background: rgba(var(--primary-text-color-rgb), 0.2);
    }
  }

  &-blue-light {
    background: rgba(var(--blue-color-rgb), 0.1);
    color: var(--blue-color);

    &:hover {
      background: rgba(var(--blue-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--blue-color);
    }
  }

  &-red {
    background: var(--red-color);
    border: 1px solid var(--red-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--red-color-rgb), 0.9);
      color: var(--white-color);
    }
  }

  &-red-light {
    background: rgba(var(--red-color-rgb), 0.1);
    color: var(--red-color);

    &:hover {
      background: rgba(var(--red-color-rgb), 0.8);
      color: var(--white-color);
    }

    .loading {
      --spinner-color: var(--red-color);
    }
  }

  &-wanted {
    background: var(--wanted-primary-color);
    border: 1px solid var(--wanted-primary-color);
    color: var(--white-color);

    &:hover {
      background: rgba(var(--wanted-primary-color-rgb), 0.9);
      color: var(--white-color);
    }
  }

  &-wanted-light {
    background: rgba(var(--wanted-primary-color-rgb), 0.1);
    color: var(--wanted-primary-color);

    &:hover {
      background: rgba(var(--wanted-primary-color-rgb), 0.8);
      color: var(--white-color);
    }
  }
}

.button-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gutter-sm);
  margin-top: var(--gutter);

  .button {
    white-space: nowrap;

    @include breakpoint(sm) {
      flex: 1;
      text-align: center;
    }
  }
}

.button-play {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;

  &:not(.is-playing) {
    .icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.2rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}
