@use "globals" as *;

.switch {
  background-color: rgba(var(--primary-text-color-rgb), 0.2);

  &[data-state="checked"] {
    background-color: var(--green-color);
  }

  &-thumb {
    background-color: var(--white-color);

    .spinner {
      --spinner-size: 1rem;
    }
  }
}
