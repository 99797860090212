@use "globals" as *;

.sidebar {
  &-body {
    background-color: var(--secondary-bg-color);
    border-color: var(--border-color);
  }

  &-header {
    display: flex;
    align-items: center;
    height: var(--navbar-height);
    padding: 0 var(--gutter);
    border-bottom: 1px solid var(--border-color);
  }

  &-footer {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--border-color);

    .nav-user {
      width: 100%;
      height: 100%;
      border-radius: 0 !important;
    }
  }

  &-menu-item {
    &.active {
      background-color: rgba(var(--theme-primary-color-rgb), 0.1);
      color: var(--theme-primary-color);
    }
  }
}

.sidebar-collapsed {
  .sidebar-menu-item {
    justify-content: center;

    span:not(.icon) {
      display: none;
    }
  }
}
