@use "globals" as *;

.distro-mockup {
  position: relative;
  margin: 0 auto;
  background-color: var(--secondary-bg-color);
  border-radius: var(--radius-lg);
  overflow: hidden;
  @include shadow-border;
  --center-logo-size: 90px;
  --orbiting-logo-size: 48px;

  @include breakpoint(sm) {
    border-radius: var(--radius);
    padding: var(--gutter-sm);
    --center-logo-size: 48px;
    --orbiting-logo-size: 24px;
  }

  &-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
    max-width: $breakpoint-sm;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .logo-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-bg-color);
    padding: var(--gutter);
    border-radius: var(--radius);
    @include shadow-border;

    @include breakpoint(sm) {
      border-radius: var(--radius-sm);
      padding: var(--gutter-sm);
    }
  }

  .center-logo {
    svg {
      width: var(--center-logo-size);
      height: var(--center-logo-size);
    }
  }

  .orbiting-logos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .orbiting-logo {
    position: absolute;

    svg {
      width: var(--orbiting-logo-size);
      height: var(--orbiting-logo-size);
    }
  }
}
