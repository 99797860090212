@use "globals" as *;

$font-path: "/fonts";

@font-face {
  font-family: "Inter";
  src:
    url("#{$font-path}/inter/Inter-Light.woff2") format("woff2"),
    url("#{$font-path}/inter/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src:
    url("#{$font-path}/inter/Inter-Regular.woff2") format("woff2"),
    url("#{$font-path}/inter/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src:
    url("#{$font-path}/inter/Inter-Medium.woff2") format("woff2"),
    url("#{$font-path}/inter/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src:
    url("#{$font-path}/inter/Inter-SemiBold.woff2") format("woff2"),
    url("#{$font-path}/inter/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src:
    url("#{$font-path}/inter/Inter-Bold.woff2") format("woff2"),
    url("#{$font-path}/inter/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Basier Circle";
  src:
    url("#{$font-path}/basier-circle/basiercircle-regular.woff2") format("woff2"),
    url("#{$font-path}/basier-circle/basiercircle-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Basier Circle";
  src:
    url("#{$font-path}/basier-circle/basiercircle-semibold.woff2") format("woff2"),
    url("#{$font-path}/basier-circle/basiercircle-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Basier Circle";
  src:
    url("#{$font-path}/basier-circle/basiercircle-bold.woff2") format("woff2"),
    url("#{$font-path}/basier-circle/basiercircle-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
