@use "globals" as *;

.banner {
  position: fixed;
  padding: 0.75rem var(--gutter);
  background-color: rgba(var(--primary-color-rgb), 0.1);
  backdrop-filter: blur(12px);
  z-index: 40;

  @include breakpoint(md) {
    height: auto;
  }

  &-fixed {
    position: fixed;
    --offset: var(--gutter);
  }

  &-content {
    display: flex;
    align-items: center;
    gap: var(--gutter);
    font-size: 1rem;
    text-wrap: balance;

    @include breakpoint(md) {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--gutter-sm);
      font-size: 0.9rem;
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(var(--primary-color-rgb), 0.3);
  }

  &-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gutter);
    justify-content: space-between;
    max-width: 400px;
    height: auto;
    border: 1px solid rgba(var(--primary-color-rgb), 0.3);
    border-radius: var(--radius);
  }

  &-bottom-left {
    left: var(--offset);
    bottom: var(--offset);

    @include breakpoint(sm) {
      max-width: 100%;
      left: var(--offset);
      right: var(--offset);
    }
  }

  &-bottom-right {
    right: var(--offset);
    bottom: var(--offset);

    @include breakpoint(sm) {
      max-width: 100%;
      left: var(--offset);
      right: var(--offset);
    }
  }

  &-free {
    background-color: rgba(var(--green-color-rgb), 0.1);
    border-top: 1px solid rgba(var(--green-color-rgb), 0.3);
  }

  &-billing {
    background-color: rgba(var(--orange-color-rgb), 0.1);
    border-top: 1px solid rgba(var(--orange-color-rgb), 0.3);
  }
}

.has-banner {
  padding-bottom: var(--banner-height);

  .banner-bottom-left,
  .banner-bottom-right {
    bottom: 5rem;
  }

  .sidebar-body {
    max-height: calc(100% - var(--banner-height));
  }
}

/* Banner Notice */
.banner-notice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: var(--gutter-sm) var(--gutter);
  text-wrap: balance;
  z-index: 40;
  backdrop-filter: blur(12px);
  transition: var(--time-fast) all var(--curve-fast);

  &.dismissed {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
    transition: var(--time-fast) all var(--curve-fast);
  }

  &-sm {
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    max-width: $breakpoint-sm;
    border-radius: var(--radius);
    margin: 0 auto;
  }
}

.has-notice {
  padding-top: var(--dynamic-banner-height);

  .sidebar-body {
    margin-top: var(--dynamic-banner-height);
    max-height: calc(100% - var(--dynamic-banner-height));
  }

  .nav {
    top: var(--dynamic-banner-height);
  }
}

.banner-primary {
  background-color: rgba(var(--primary-color-rgb), 0.8);
  color: var(--white-color);

  a {
    color: var(--white-color);
  }
}

.banner-gradient {
  background: linear-gradient(
    to right,
    rgba(var(--theme-primary-color-rgb), 0.8),
    rgba(var(--theme-secondary-color-rgb), 0.8)
  );

  color: var(--white-color);

  a {
    color: var(--white-color);
  }
}

.banner-light {
  background-color: rgba(var(--primary-bg-color-rgb), 0.7);
  color: var(--primary-text-color);
  @include shadow-border;
}

.banner-error {
  background-color: rgba(var(--red-color-rgb), 0.1);
  color: var(--red-color);

  a {
    color: var(--red-color);
  }
}
