@use "globals" as *;

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60vh;
  border-radius: var(--radius-lg);
  overflow: hidden;

  h1 {
    font-size: 3.5rem;
    color: var(--white-color);
    line-height: 1.1;
    margin: 0;
    font-weight: 600;
    text-wrap: balance;

    @include breakpoint(sm) {
      font-size: 2.5rem;
    }
  }

  h2 {
    color: var(--white-color);
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 300;
    opacity: 0.85;
    margin: 0;
    max-width: 48ch;
    text-wrap: balance;

    @include breakpoint(md) {
      font-size: 1.25rem;
    }

    @include breakpoint(sm) {
      font-size: 1.25rem;
    }
  }

  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    z-index: 1;
  }

  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(var(--overlay-color-rgb), 1), rgba(var(--overlay-color-rgb), 0));
    z-index: 10;
  }

  &-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--gutter);
    z-index: 20;

    @include breakpoint(sm) {
      padding: var(--gutter);
    }
  }
}
