@use "globals" as *;

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  font-weight: 600;
  margin: var(--gutter) 0;
  padding: 6rem var(--gutter);
  background: rgba(var(--secondary-bg-color-rgb), 0.25);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);

  > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    color: var(--neutral-color);
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.25rem;
    font-weight: 500;
    color: rgba(var(--primary-text-color-rgb), 0.5);
    margin-bottom: 0;
    text-wrap: balance;
    max-width: $breakpoint-sm;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }

  &-horizontal {
    flex-direction: row;
    gap: var(--gutter);

    .icon {
      width: 2rem;
      height: 2rem;
      padding: 0;
      background-color: transparent;
      border: 0;
      margin: 0;
    }
  }

  &-small {
    padding: var(--gutter);
    margin: 0;

    .emoji {
      display: block;
      font-size: 1.5rem;
      margin-right: var(--gutter-sm);
      margin-bottom: 0;
    }

    p {
      font-size: 1rem;
    }
  }

  &-ghost {
    background: transparent;
    border: 0;
  }

  &-error {
    background: rgba(var(--red-color-rgb), 0.05);
    border-color: rgba(var(--red-color-rgb), 0.2);

    p {
      color: var(--primary-text-color);
    }

    > .icon {
      color: var(--red-color);
      opacity: 1;
    }
  }

  &-success {
    background: rgba(var(--green-color-rgb), 0.05);
    border-color: rgba(var(--green-color-rgb), 0.2);

    p {
      color: var(--primary-text-color);
    }

    > .icon {
      color: var(--green-color);
      opacity: 1;
    }
  }
}
