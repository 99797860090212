@use "globals" as *;

.dialog {
  background: var(--primary-bg-color);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  max-height: calc(100vh - 2 * var(--gutter-md));

  @include breakpoint(md) {
    max-width: calc(100vw - 2 * var(--gutter));
    max-height: calc(100vh - 2 * var(--gutter));
  }

  &-overlay {
    background: rgba(var(--overlay-color-rgb), 0.8);
  }
}
