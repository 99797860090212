@use "globals" as *;

.track-player {
  position: relative;
  aspect-ratio: 1 / 1;
  width: var(--track-cover-size);
  height: var(--track-cover-size);
  flex-shrink: 0;
  border-radius: var(--track-radius);
  overflow: hidden;

  &.is-playing,
  &:hover {
    .overlay {
      opacity: 1;
      transform: scale(1);
      transition: var(--time-fast) var(--curve-fast);
    }
  }

  .track-thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background-color: rgba(var(--primary-color-rgb), 0.8);
    transform: scale(0);
    transform-origin: center;
    transition: var(--time-fast) var(--curve-fast);
    z-index: 10;

    i,
    svg {
      color: var(--white-color);
    }
  }

  .track-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6px;
    background-color: rgba(var(--white-color-rgb), 0.5);

    > div {
      height: 100%;
      background-color: var(--white-color);
    }
  }
}
