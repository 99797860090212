@use "globals" as *;

.notification-dropdown {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0.5rem;
  right: 0.4rem;
  transform: translate(50%, -50%);
  background-color: rgba(var(--red-color-rgb), 0.9);
  color: var(--white-color);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-family: var(--font-family-mono);
  font-weight: 600;
  padding: 0.1rem 0.4rem;
  z-index: 1;

  &.double-digit {
    right: 0.2rem;
  }

  &.over-limit {
    right: 0.1rem;
    padding: 0.1rem 0.3rem;
  }

  &.hide-count {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--blue-color);
    top: 0.6rem;
    right: 0.6rem;
    padding: 0;
  }
}

.notification-section {
  h2 {
    font-size: 1.5rem;
  }

  .notification-item {
    gap: var(--gutter);
    background-color: rgba(var(--secondary-bg-color-rgb), 0.1);
    padding: var(--gutter);
    border-radius: var(--radius);
    @include shadow-border;
    margin-bottom: var(--gutter-sm);
    transition: var(--time) all var(--curve-fast);
    --notification-icon-size: 3rem;

    &:hover {
      background-color: rgba(var(--secondary-bg-color-rgb), 1);
      transition: var(--time) all var(--curve-fast);
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      font-weight: 600;
    }
  }
}

// dropdown
.notification-dropdown-menu {
  .dropdown-menu-list {
    max-height: 400px;
    overflow: auto;

    @include breakpoint(md) {
      max-height: 60vh;
    }
  }

  .notification-item {
    font-size: 0.85rem;
    gap: var(--gutter);
    --notification-icon-size: 2.5rem;

    &-inner {
      flex-direction: row;
      align-items: flex-start;
      gap: var(--gutter);
    }

    &-cta {
      display: none;
    }
  }

  .button {
    width: 100%;
  }
}

// notification item
.notification-item-inner {
  display: flex;
  align-items: flex-start;
  gap: var(--gutter);
}

.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-content {
    font-size: 0.9rem;
  }

  &-inner {
    @include breakpoint(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.1);
    border-radius: var(--radius-sm);
    width: var(--notification-icon-size);
    height: var(--notification-icon-size);
    flex-shrink: 0;

    i,
    svg {
      font-size: 1rem;
    }

    @include breakpoint(sm) {
      display: none;
    }
  }

  &-time {
    font-size: 0.8rem;
    color: var(--text-color-light);
    opacity: 0.6;
    white-space: nowrap;

    @include breakpoint(sm) {
      font-size: 0.9rem;
    }
  }

  &-cta {
    .button-label {
      white-space: nowrap;
    }

    @include breakpoint(sm) {
      .button-label {
        display: none;
      }

      .button i,
      .button svg {
        margin-right: 0;
      }
    }
  }
}
