@use "globals" as *;

.player {
  &-card {
    background-color: var(--secondary-bg-color);
    border-radius: var(--radius);
    border: 1px solid var(--border-color);

    &-content {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: var(--gutter);
      padding: var(--gutter);
    }

    .track-card-main {
      display: flex;
      align-items: flex-start;
      gap: var(--gutter);
    }

    .track-cover {
      --track-cover-size: 128px;
    }

    .track-info {
      h1 {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 1.2rem;
      }

      h5 {
        font-size: 1rem;
      }
    }

    .track-artists {
      display: flex;
      flex-wrap: wrap;
      gap: var(--gutter-sm);

      a {
        line-height: 1;
      }
    }
  }

  &-device {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 var(--radius) var(--radius);
    border-top: 1px solid var(--border-color);
    padding: var(--gutter);

    p {
      font-size: 1rem;
      font-weight: 500;
      margin: 0;

      .emoji {
        margin-right: var(--gutter-sm);
      }
    }
  }

  .player-is-playing {
    display: flex;
    align-items: center;
    gap: var(--gutter-sm);
  }

  &-link {
    cursor: pointer;
    transition: var(--time) all var(--curve-fast);

    &:hover {
      opacity: 0.8;
      transform: scale(0.98);
      transition: var(--time) all var(--curve-fast);
    }
  }

  &-vertical {
    .player-card {
      background-color: var(--secondary-bg-color);

      .track-card-main {
        padding: var(--gutter);
      }

      .track-cover {
        --track-cover-size: 100%;
      }

      > div {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.player-online {
  .player-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 0;
    border-top: 1px solid var(--border-color);
    border-radius: 0;
    padding: var(--gutter);

    .track-card-main {
      align-items: center;
    }

    .track-cover {
      --track-cover-size: 42px;
      border-radius: var(--radius-sm);
    }

    .track-artists a {
      font-size: 0.85rem;
    }
  }
}
